<template>
<div v-if="extraReceptionPriceTotal > 0" class="order-price__row mb-8 leading-normal">
  <div class="text-sm text-grey-darkest">{{ $t('extraReception.addPerson') }}</div>
  <div class="text-sm text-grey-darker text-right">
    <span class="text-grey-darkest">{{ selectedCurrency }} {{ extraReceptionPriceTotal | currency }}</span>
    <span v-show="!isCurrencyMatchHotelCurrency" class='text-sm text-grey-dark block mt-4'>( {{ hotelHotelOriginCurrency }} {{ extraReceptionPriceTotal | currency({ outputCurrency: hotelHotelOriginCurrency }) }} )</span>
  </div>
</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ExtraReceptionPrice',
  computed: {
    ...mapGetters({
      selectedCurrency: 'selectedCurrency',
      hotelHotelOriginCurrency: 'hotel/hotelOriginCurrency',
      extraReceptionPriceTotal: 'order/extraReceptionPriceTotal'
    }),
    isCurrencyMatchHotelCurrency () {
      return this.selectedCurrency === this.hotelHotelOriginCurrency
    }
  }
}
</script>
