<template>
  <div class='addon-card__orders'>
    <div class="addon-card__orders__brief md:flex mb-16 w-full">
      <figure
        :style="`background-image: url(${addonDetail.cover_images[0]})`"
        class='block addon-card__orders__brief__cover mr-16 rounded-sm bg-cover bg-center bg-no-repeat'>
      </figure>
      <div class="addon-card__orders__brief__content flex flex-col">
        <div class="addon-card__orders__brief__content__title mb-8 flex flex-col md:flex-row items-start">
          <h5 class='text-grey-darker font-medium'>{{ addonName }}</h5>
        </div>
        <div
            v-for='(item, index) in addon.items'
            :key="addon.id + '-' + $dayjs(item.date).format('YYYY-MM-DD') + '-' + item.session + '-' + index"
            class="addon-card__orders__plan flex flex-col md:flex-row items-center text-sm text-grey-darkest"
          >
            <h6 class="addon-card__orders__plan__datetime mr-auto">
              <span>
                {{ displayDate(item) }}
              </span>
              <span v-show="!isGoods(item)">
                {{ displayTime(item) }}
              </span>
              <span class='mr-auto md:mr-40'>x {{ item.qty }} {{ item.unit }}</span>
            </h6>
        </div>
      </div>
    </div>
    <div class="flex justify-end items-center">
      <p class='text-sm font-bold'>
        {{ selectedCurrency }} {{ addonTotal | currency }}
      </p>
      <span
        class="ml-8 text-grey-dark underline font-normal text-xs cursor-pointer"
        @click="handleShowDetail"
      >
        {{ $t('detail') }}
      </span>
      <small v-if="hasExtraTaxFeeConfig" class="block mt-4 text-right">({{ $t('exclude_tax') }})</small>
    </div>
    <div class="mt-16 collapseBlock" :class="{'open': !isMask}" v-if="hasCancelPolicy">
      <h6 class="mb-8" @click="isMask = !isMask"><i class="owl-caret-right"></i>{{ $t('cancelPolicy') }}</h6>
      <div class="collapseBlock__body">
        <p class="text-sm text-grey-darker leading-normal whitespace-pre-wrap" v-html="$options.filters.contentParser(this.addon.items[0].cancelPolicy)"></p>
        <div v-show="isMask" class="collapseBlock__body__mask" @click="isMask = false"></div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'
import contentWithCover from '@/components/modals/contentWithCover'
import itemDetail from '@/components/modals/itemDetail'

export default {
  name: 'addon-card-orders',
  props: {
    addon: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      addonDetail: {},
      isMask: true
    }
  },
  computed: {
    ...mapGetters([
      'selectedCurrency',
      'addon/addons'
    ]),
    addonName: function () {
      return this.$options.filters.contentParser(this.addonDetail.name)
    },
    sessionTotal: function () {
      return _.sumBy(this.addon.items, item => item.price_without_fee * item.qty)
    },
    hasCancelPolicy: function () {
      return !_.isEmpty(this.addon.items[0].cancelPolicy)
    },
    hasExtraTaxFeeConfig () {
      return this.addon.items[0]?.feeConfig?.length
    },
    addonTotal () {
      return this.addon.items.reduce((pv, cv) => pv + cv.price_without_fee * cv.qty, 0)
    }
  },
  methods: {
    isGoods: function (item) {
      return item.type === 'hotel-goods'
    },
    displayDate: function (item) {
      return this.$dayjs(item.date).format('YYYY-MM-DD')
    },
    displayTime: function (item) {
      const formated = this.$dayjs().format('YYYY/MM/DD ') + item.session
      let timeParse = this.$dayjs(formated).format('HH:mm')
      if (!item.session) {
        timeParse = this.$t('unlimitedTime')
      }
      return timeParse
    },
    openCancelPolicy: function () {
      this.$modal.show(contentWithCover, {
        isFeatures: false,
        context: this.$options.filters.contentParser(this.addon.items[0].cancelPolicy)
      },
      {
        adaptive: true,
        name: 'features-modal',
        height: 'auto',
        width: '100%',
        maxWidth: 450,
        scrollable: true
      })
    },
    handleShowDetail () {
      console.log('handleShowDetail')
      this.$modal.show(itemDetail, {
        title: this.$t('addonDetail'),
        addon: this.addon,
        type: 'addon',
        addonTotal: this.addonTotal
      },
      {
        adaptive: true,
        name: 'item-detail',
        height: 'auto',
        maxWidth: 500,
        width: '100%'
      })
    }
  },
  watch: {
    addon: {
      handler: function () {
        this.addonDetail = _.find(this['addon/addons'], addon => addon.id === this.addon.id)
      },
      immediate: true
    }
  }
}
</script>

<style lang='sass' scoped>
.addon-card__orders
  @apply py-24
  &:not(:last-of-type)
    @apply border-b-1 border-grey-lighter
  &__brief__cover
    @apply flex-no-shrink bg-center bg-cover mb-16
    height: 130px
    width: 100%
    @screen md
      @apply mb-0
      height: 96px
      width: 138px
  &__plan
    @apply pt-4
.collapseBlock
  h6
    @apply cursor-pointer
  &.open
    .collapseBlock__body
      max-height: 100%
    .owl-caret-right
      @apply inline-block
      transform: rotate(90deg)
  &__body
    max-height: 4.5rem
    @apply overflow-hidden relative
    &__mask
      @apply block w-full absolute cursor-pointer
      bottom: 0
      content: ''
      height: 4.5rem
      background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%)
</style>
