var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "item-detail rounded-lg" }, [
    _c("div", { staticClass: "item-detail__header" }, [
      _c("div", [_vm._v(_vm._s(_vm.title))]),
      _c(
        "div",
        { staticClass: "cursor-pointer", on: { click: _vm.handleClose } },
        [_c("i", { staticClass: "owl-status-error" })]
      )
    ]),
    _vm.type === "room"
      ? _c(
          "div",
          { staticClass: "item-detail__content" },
          [
            _vm._l(_vm.plan.qty, function(count) {
              return _c("RoomDetail", {
                key: _vm.plan.uid + "_" + count,
                attrs: { index: count - 1, plan: _vm.plan }
              })
            }),
            _c("div", { staticClass: "flex justify-between my-8" }, [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.$t("total") +
                      " (" +
                      _vm.$t("room_price_has_tax_included") +
                      ")"
                  )
                )
              ]),
              _c("span", { staticClass: "font-bold" }, [
                _vm._v(
                  _vm._s(_vm.selectedCurrency) +
                    " " +
                    _vm._s(_vm._f("currency")(_vm.totalPrice))
                )
              ])
            ])
          ],
          2
        )
      : _vm._e(),
    _vm.type === "addon"
      ? _c(
          "div",
          { staticClass: "item-detail__content" },
          [
            _c("h5", { staticClass: "text-grey-darker font-medium mb-16" }, [
              _vm._v(_vm._s(_vm.addonName))
            ]),
            _vm._l(_vm.addon.items, function(item, index) {
              return _c(
                "div",
                {
                  key:
                    _vm.addon.id +
                    "-" +
                    _vm.$dayjs(item.date).format("YYYY-MM-DD") +
                    "-" +
                    item.session +
                    "-" +
                    index,
                  staticClass: "flex justify-between text-base"
                },
                [
                  _c("h6", [
                    _c("span", [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.displayDate(item)) +
                          "\n        "
                      )
                    ]),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.isGoods(item),
                            expression: "!isGoods(item)"
                          }
                        ]
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.displayTime(item)) +
                            "\n        "
                        )
                      ]
                    )
                  ]),
                  _c("h6", { staticClass: "text-right w-1/2" }, [
                    _c("span", { staticClass: "mr-8" }, [
                      _vm._v(
                        _vm._s(_vm.selectedCurrency) +
                          " " +
                          _vm._s(
                            _vm._f("currency")(item.price_without_fee, {
                              inputCurrency: item.currency,
                              isBonus: true
                            })
                          )
                      )
                    ]),
                    _c("span", { staticClass: "mr-8" }, [
                      _vm._v("x " + _vm._s(item.qty) + " " + _vm._s(item.unit))
                    ])
                  ]),
                  _c("h6", [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.selectedCurrency + " " + _vm.addonRowTotal(item)
                        )
                      )
                    ]),
                    _vm.hasExtraTaxFeeConfig
                      ? _c("small", { staticClass: "block mt-4 text-right" }, [
                          _vm._v("(" + _vm._s(_vm.$t("exclude_tax")) + ")")
                        ])
                      : _vm._e()
                  ])
                ]
              )
            }),
            _c("div", { staticClass: "flex justify-between mb-8 mt-16" }, [
              _c("span", [_vm._v(_vm._s(_vm.$t("total")))]),
              _c("span", { staticClass: "font-bold" }, [
                _vm._v(
                  _vm._s(_vm.selectedCurrency) +
                    " " +
                    _vm._s(_vm._f("currency")(_vm.addonTotal))
                )
              ])
            ])
          ],
          2
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }