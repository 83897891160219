<template>
  <div class="hotel-card-order">
    <div class="md:flex">
      <figure
        :style="`background-image: url('${mapRoomCover}')`"
        class='block hotel-card-order__cover mr-16 rounded-sm bg-cover bg-center bg-no-repeat'>
      </figure>
      <div class="hotel-card-order__content flex flex-col w-full">
        <h5 class='mb-8 text-grey-darkest font-medium'>{{ plan.roomName }}</h5>
        <p class='text-sm text-grey-dark mb-8 md:mb-auto'>{{ plan.planTitle | contentParser }}</p>
        <div class="hotel-card-order__content__row flex flex-col md:flex-row md:items-center justify-between">
          <div class="hotel-card-order__content__detail__amount flex w-full justify-between md:text-right">
            <div class='text-grey-darkest text-xs md:w-1/2'>
              <div class="text-sm text-left flex">
                <div>x {{ $t('orderUnit', { stock: plan.qty, unit: plan.unit }) }}</div>
                <div v-if="extraReceptionTotalCount > 0">{{ `, ${$t('extraReception.total')}+ ${extraReceptionTotalCount}${$t('person')}` }}</div>
              </div>
            </div>
            <div class='text-grey-darkest text-xs md:w-1/3 lg:w-1/5 flex-grow text-right'>
              <div class="flex justify-end items-center items-center">
                <p class='text-sm font-bold'>
                  {{ selectedCurrency }} {{ planTotal | currency }}
                </p>
                <span
                  class="ml-8 text-grey-dark underline font-normal text-xs cursor-pointer"
                  @click="handleShowDetail"
                >
                  {{ $t('detail') }}
                </span>
              </div>
              <small v-if="hasExtraTaxFeeConfig" class="block mt-4 text-grey-dark">({{ $t('exclude_tax') }})</small>
              <small v-else class="block mt-4 text-grey-dark">{{ $t('room_price_has_tax_included') }}</small>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-16 collapseBlock" :class="{'open': !isMask}">
      <h6 class="mb-8" @click="isMask = !isMask"><i class="owl-caret-right"></i>{{ $t('cancelPolicy') }}</h6>
      <div class="collapseBlock__body">
        <p class="text-sm text-grey-darker leading-normal whitespace-pre-wrap" v-html="$options.filters.contentParser(cancelPolicyContent)"></p>
        <div v-show="isMask" class="collapseBlock__body__mask" @click="isMask = false"></div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'
import contentWithCover from '@/components/modals/contentWithCover'
import itemDetail from '@/components/modals/itemDetail'

export default {
  name: 'hotel-card-order',
  props: {
    plan: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      isMask: true
    }
  },
  computed: {
    ...mapGetters({
      hasExtraTaxFeeConfig: 'hotel/hasExtraTaxFeeConfig'
    }),
    ...mapGetters([
      'selectedCurrency',
      'hotel/rooms',
      'hotel/hotel',
      'search/dateRange',
      'search/nights',
      'order/extraReceptionPriceConfig'
    ]),
    mapRoomCover: function () {
      const room = _.find(this['hotel/rooms'], room => room.id === this.plan.roomId)
      return room.images[0]
    },
    avgFee: function () {
      return this.plan.planFee / this['search/nights']
    },
    planTotal: function ({ extraReceptionTotalPrice }) {
      const total = (this.plan.avgPrice - this.avgFee) * this.plan.qty * this['search/nights']
      return total + extraReceptionTotalPrice
    },
    extraReceptionTotalAdults: function () {
      return this.plan.extraReception.reduce((pv, cv) => pv + cv.adults, 0)
    },
    extraReceptionTotalChildren: function () {
      return this.plan.extraReception.reduce((pv, cv) => pv + cv.children, 0)
    },
    extraReceptionTotalCount: function ({ extraReceptionTotalAdults, extraReceptionTotalChildren }) {
      return extraReceptionTotalAdults + extraReceptionTotalChildren
    },
    extraReceptionTotalPrice: function ({ extraReceptionTotalAdults, extraReceptionTotalChildren }) {
      return (extraReceptionTotalAdults * this['order/extraReceptionPriceConfig'].adults + extraReceptionTotalChildren * this['order/extraReceptionPriceConfig'].children) * this['search/nights']
    },
    isUseOwltingCancelPolicy () {
      return this['hotel/hotel']?.enable_owlting_cancel_policy
    },
    cancelPolicyContent () {
      return this.isUseOwltingCancelPolicy
        ? this.$t('owlTingCancelPolicy')
        : this.$options.filters.contentParser(this.plan.cancelPolicy.content)
    }
  },
  methods: {
    openCancelPolicy: function () {
      this.$modal.show(contentWithCover, {
        isFeatures: false,
        context: this.$options.filters.contentParser(this.plan.cancelPolicy.content)
      },
      {
        adaptive: true,
        name: 'features-modal',
        height: 'auto',
        width: '100%',
        maxWidth: 450,
        scrollable: true
      })
    },
    handleShowDetail: function () {
      this.$modal.show(itemDetail, {
        title: this.$t('roomDetail'),
        plan: this.plan,
        type: 'room'
      },
      {
        adaptive: true,
        name: 'item-detail',
        height: 'auto',
        maxWidth: 500,
        width: '100%'
      })
    }
  }
}
</script>

<style lang='sass' scoped>
.hotel-card-order
  @apply py-24
  &:not(:last-of-type)
    @apply border-b-1 border-grey-lighter
  &__cover
    @apply flex-no-shrink bg-center bg-cover mb-16
    height: 130px
    width: 100%
    @screen md
      @apply mb-0
      height: 96px
      width: 138px
.collapseBlock
  h6
    @apply cursor-pointer
  &.open
    .collapseBlock__body
      max-height: 100%
    .owl-caret-right
      @apply inline-block
      transform: rotate(90deg)
  &__body
    max-height: 4.5rem
    @apply overflow-hidden relative
    &__mask
      @apply block w-full absolute cursor-pointer
      bottom: 0
      content: ''
      height: 4.5rem
      background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%)
</style>
