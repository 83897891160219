<template>
  <main class="payment">
    <processSteps fullWidth style="max-width: 724px; margin-left: auto; margin-right: auto;" />

    <section class="container" style="max-width: 724px">
      <div class="payment__body">
        <div class="payment__body__infos lg:pr-12">
          <!-- 訂購人資訊 -->
          <blockCard
            class='orderer-info'
            :title="$t('customerInfo')">
            <div class="order-info__row flex flex-wrap">
              <div class="form-group w-full sm:w-1/2 sm:pr-24">
                <label class='is-required'>{{ $t('lastName') }}</label>
                <input
                  type="text"
                  class='input'
                  :class="{ 'input-error': validation.firstError('customer.lastName') }"
                  :disabled="isSubmitting"
                  :placeholder="$t('lastNamePlaceholder')"
                  v-model="customer.lastName"
                >
                <span
                  v-if="validation.firstError('customer.lastName')"
                  class='format-error-notify'>{{ lastNameValidatedErrorMsg }}</span>
              </div>
              <div class="form-group w-full sm:w-1/2">
                <label class='is-required'>{{ $t('firstName') }}</label>
                <input
                  type="text"
                  class='input'
                  :class="{'input-error': validation.firstError('customer.firstName') }"
                  :disabled="isSubmitting"
                  :placeholder="$t('firstNamePlaceholder')"
                  v-model="customer.firstName"
                >
                <span
                  v-if="validation.firstError('customer.firstName')"
                  class='format-error-notify'>{{ firstNameValidatedErrorMsg }}</span>
              </div>
              <div class="form-group w-full sm:w-1/2 sm:pr-24">
                <label class='is-required'>{{ $t('country') }}</label>
                <multiSelect
                  v-model='currentCountrySelect'
                  track-by='name'
                  label='name'
                  :options='countries'
                  :show-labels="false"
                  :close-on-select="true"
                  :allow-empty="false"
                  :disabled="isSubmitting"
                  @select='selectCountry' />
              </div>
              <div class="form-group w-full sm:w-1/2">
                <label class='is-required'>{{ $t('phone') }}</label>
                <input
                  type="tel"
                  class='input'
                  :class="{ 'input-error': validation.firstError('customer.phone') }"
                  :disabled="isSubmitting"
                  placeholder="+886988777654 or 0988777654"
                  inputmode="tel"
                  v-model="customer.phone">
                <span
                  v-if="validation.firstError('customer.phone')"
                  class='format-error-notify'>{{ phoneValidatedErrorMsg }}</span>
              </div>
              <div class="form-group w-full">
                <label class='is-required'>{{ $t('email') }}</label>
                <input
                  type="email"
                  class='input'
                  :class="{ 'input-error': validation.firstError('customer.email') }"
                  :disabled="isSubmitting"
                  inputmode="email"
                  v-model="customer.email">
                <span
                  v-if="validation.firstError('customer.email')"
                  class='format-error-notify'>{{ emailValidatedErrorMsg }}</span>
              </div>
              <!-- <div class="form-group w-full customer-number-label flex">
                <label class='is-required'>{{ $t('unit.people') }}</label>
                <label class="customer-number-label__description text-grey-darkest text-sm">{{ $t('totalRoomCapacity', { capacity:maxPeopleInTotal }) }}</label>
              </div> -->
              <!-- <div class="form-group w-full sm:flex">
                <div
                  class="form-group w-full sm:w-1/3 sm:pr-12"
                  :class="{ 'selector-error': validation.firstError('customerNumber.adult') }">
                  <div>
                    <label class="selector-label">{{ $t('adult') }}</label>
                    <multiSelect
                      v-model="customerNumber.adult"
                      :placeholder="$t('quantity')"
                      :options='adultOption'
                      :show-labels="false"
                      :close-on-select="true"
                      :allow-empty="false"
                      :disabled="isSubmitting"
                      :searchable="false">
                      <template #noOptions>{{ $t('noOptions') }}</template>
                    </multiSelect>
                  </div>
                  <span
                    v-if="validation.firstError('customerNumber.adult')"
                    class='format-error-notify'>{{ this.$t('errorMsg.empty') }}</span>
                </div>
                <div
                  class="form-group w-full sm:w-1/3 sm:pr-12"
                  :class="{ 'selector-error': validation.firstError('customerNumber.child') }"
                  v-if="hotelReceptionConfig.child.isReceive">
                  <div>
                    <label class="selector-label">{{ $t('child') }}</label>
                    <multiSelect
                      v-model="customerNumber.child"
                      :placeholder="$t('quantity')"
                      :options='childOption'
                      :show-labels="false"
                      :close-on-select="true"
                      :allow-empty="false"
                      :disabled="isSubmitting"
                      :searchable="false" />
                  </div>
                  <span
                    v-if="validation.firstError('customerNumber.child')"
                    class='format-error-notify'>{{ this.$t('errorMsg.empty') }}</span>
                </div>
                <div
                  class="form-group w-full sm:w-1/3"
                  v-if="hotelReceptionConfig.infant.isReceive"
                  :class="{ 'selector-error': validation.firstError('customerNumber.infant') }">
                  <div>
                    <label class="selector-label">{{ $t('infant') }}</label>
                    <multiSelect
                      v-model="customerNumber.infant"
                      :placeholder="$t('quantity')"
                      :options='infantOption'
                      :show-labels="false"
                      :close-on-select="true"
                      :allow-empty="false"
                      :disabled="isSubmitting"
                      :searchable="false" />
                  </div>
                  <span
                    v-if="validation.firstError('customerNumber.infant')"
                    class='format-error-notify'>{{ this.$t('errorMsg.empty') }}</span>
                </div>
              </div> -->
              <div class="form-group w-full">
                <label>{{ $t('specialNeeds') }}</label>
                <textarea
                  :placeholder="$t('order.specific')"
                  class="input w-full p-16 mb-24"
                  v-model="customer.specific"
                  :disabled="isSubmitting"
                  cols="30" rows="6"></textarea>
                <div class="specific-request__note flex items-center">
                  <i class="owl-status-triangle-warning mr-8"></i>
                  <p class='text-grey-darkest text-sm'>{{ $t('orderWarningMsg') }}</p>
                </div>
              </div>
            </div>
          </blockCard>

          <!-- 注意事項 -->
          <blockCard
            class='precautions'
            v-if="isPrecautions"
            :title="$t('checkInInstructions')">
            <p class='text-sm text-grey-darkest mb-4 whitespace-pre-wrap leading-normal' v-html="this.hotelHotel.terms"></p>
          </blockCard>

          <!-- 訂單細項 -->
          <blockCard
            noPadding
            class="order-detail">
            <div class='order-detail__head py-24 md:p-24 border-b-1 border-grey-lighter'>
              <h4 class='font-medium mb-16'>{{ $t('orderDetail') }}</h4>
              <div class="order-detail__head text-grey-darkest flex flex-col md:flex-row">
                <div class="order-detail__head__row mb-16 md:mb-0 w-full md:w-1/2">
                  <i class="owl-calendar mr-8 text-grey text-xl"></i>
                  <span>{{ this.searchDateRange.start | dayFormat }}</span>
                  <span> - </span>
                  <span>{{ this.searchDateRange.end | dayFormat }}</span>
                  <small class="ml-8 text-grey-dark">( {{ diffDays + 1 }} {{ $tc('day', diffDays + 1) }} {{ diffDays }} {{ $tc('night', diffDays) }} )</small>
                </div>
                <div class="order-detail__head__row w-full md:w-1/2">
                  <i class="owl-user-group mr-8 text-grey text-xl"></i>
                  <span>{{ this.searchPeople.adult }} {{ $tc('adult', this.searchPeople.adult) }}</span>
                  <span>, {{ this.searchPeople.child }} {{ $tc('child', this.searchPeople.child) }}</span>
                  <span>, {{ this.searchPeople.infant }} {{ $tc('infant', this.searchPeople.infant) }}</span>
                </div>
              </div>
            </div>
            <div class="order-detail__items md:px-24">
              <hotelCardOrder v-for="plan in this.orderPlans" :key="plan.id" :plan="plan"></hotelCardOrder>
              <addonCardOrder v-for="addon in this.orderAddons" :key="addon.id" :addon="addon"></addonCardOrder>
            </div>
          </blockCard>

          <blockCard
            noPadding
            class="order-price">
            <div class="order-price__head p-24 pb-0">
              <h4 class='font-medium mb-16'>{{ $t('order_payment_detail') }}</h4>
              <!-- hotel 可用折價券 -->
              <div v-if="isHotelCouponActive">
                <div class="order-price__coupon">
                  <div class="order-price__coupon-code">
                    <div class="flex flex-wrap md:flex-no-wrap flex-initial justify-between">
                      <div class="order-price__coupon-code__input-group w-full md:mr-16">
                        <div class="text-sm mb-16 ml-4">{{ $t('officialCoupon') }}</div>
                        <label class="input input-buttons coupon-input">
                          <input
                            v-model="couponCode"
                            type="text"
                            :placeholder="$t('couponPlaceHolder')"
                            :disabled="isSubmitting"
                            @keypress.enter="onCouponSubmit">
                          <button class="input-btn custom-blue-input-btn" @click="onCouponSubmit">{{ $t('use') }}</button>
                        </label>
                        <div v-show="couponResultErrors.length" class="flex flex-col text-sm mt-8">
                          <span
                            v-for="error in couponResultErrors"
                            :key="error"
                            class="text-orange-dark leading-normal">{{ error }}</span>
                        </div>
                      </div>
                      <!-- <div class="border-r border-grey-lighter flex-shrink"></div> -->
                      <div v-if="isCouponValid && isCouponTriggered" class="order-price__coupon-code__result w-full mt-8 lg:mt-0">
                        <div v-for="(coupon, index) in previewResult.coupons" class="text-sm mb-8" :key="index">
                          <div class="applied-coupon border-1 border-grey-lighter border-l-8">
                            <i @click="removeCoupon" class="owl-status-error applied-coupon__close"></i>
                            <h6 class="text-grey-darkest font-bold text-sm">{{ coupon.code }}</h6>
                            <p class="text-green-darker pr-8 inline-block">{{ couponDiscountText(coupon) }}</p>
                            <small v-show="coupon.limit_max_price > 0" class="text-xs text-grey-dark">( {{ $t('maximumDiscount') }} {{ selectedCurrency }} {{ coupon.limit_max_price | currency({ outputCurrency: hotelHotelOriginCurrency }) }} )</small>
                          </div>
                        </div>
                      </div>

                      <!-- 條件不符時顯示折扣券內容 -->
                      <div v-show="shouldShowCouponCodeConditions" class="order-price__coupon-code__all-content w-full">
                        <div class="order-price__coupon-code__all-content__head">
                          <h6 class="text-sm text-grey-darkest mb-8 font-bold">{{ appliedCouponContent.code }}</h6>
                        </div>
                        <div class="order-price__coupon-code__all-content__body">
                          <div class="order-price__coupon-code__all-content__body__row">
                            <label>{{ $t('coupon_cost_threshold') }}：</label>
                            <span>{{ appliedCouponContentPriceCondition }}</span>
                          </div>
                          <div class="order-price__coupon-code__all-content__body__row">
                            <label>{{ $t('coupon_apply_check_in_date') }}：</label>
                            <span>{{ appliedCouponContentDate }} {{ appliedCouponContentDateSubNote ? `(${appliedCouponContentDateSubNote})` : null }}</span>
                          </div>
                          <div class="order-price__coupon-code__all-content__body__row">
                            <label>{{ $t('coupon_apply_rooms') }}：</label>
                            <span v-html="appliedCouponContentRooms" />
                          </div>
                          <div class="order-price__coupon-code__all-content__body__row">
                            <label>{{ $t('coupon_apply_plans') }}：</label>
                            <span v-html="appliedCouponContentPlans" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="order-price__body p-24">
              <!-- 只有總價(resultCommon) -->
              <template v-if="!hasDeposit && !isCouponAvailable">
                <div class="order-price__row mb-8 leading-normal">
                  <label class='text-grey-darkest'>
                    {{ $t('roomsCost') }}
                    <small v-if="!hasPlanFeeConfig">({{ $t('room_price_has_tax_included') }})</small>
                  </label>
                  <div class="text-right">
                    <span class="block text-sm">{{ selectedCurrency }} <big class='text-sm'>{{ resultCommon.plansTotalPrice | currency }}</big></span>
                    <span v-show="!isCurrencyMatchHotelCurrency" class='text-sm text-grey-dark block mt-4'>
                      ( {{ hotelHotelOriginCurrency }} {{ resultCommon.plansTotalPrice | currency({ outputCurrency: hotelHotelOriginCurrency }) }} )
                    </span>
                  </div>
                </div>
                <template v-if="hasPlanFeeConfig">
                  <div v-for="fee in planFeeConfigs" :key="`fee-${fee.id}-${fee.name}`" class="order-price__row mb-8 leading-normal indent">
                    <label class="text-sm text-grey-darkest">{{ fee.name }}</label>
                    <div class="text-right">
                      <span class="block text-sm text-grey-darkest">{{ selectedCurrency }} <big class='text-sm'>{{ fee.price | currency }}</big></span>
                      <span v-show="!isCurrencyMatchHotelCurrency" class='text-sm text-grey-dark block mt-4'>
                        ( {{ hotelHotelOriginCurrency }} {{ fee.price | currency({ outputCurrency: hotelHotelOriginCurrency }) }} )
                      </span>
                    </div>
                  </div>
                </template>

                <extraReceptionPrice />

                <div v-if="hasAddons" class="order-price__row mb-8 leading-normal">
                  <label class="text-sm text-grey-darkest">{{ $t('addonsCost') }}</label>
                  <div class="text-right">
                    <span class="block text-sm text-grey-darkest">{{ selectedCurrency }} <big class='text-sm'>{{ resultCommon.addonsTotalPrice | currency }}</big></span>
                    <span v-show="!isCurrencyMatchHotelCurrency" class='text-sm text-grey-dark block mt-4'>
                      ( {{ hotelHotelOriginCurrency }} {{ resultCommon.addonsTotalPrice | currency({ outputCurrency: hotelHotelOriginCurrency }) }} )
                    </span>
                  </div>
                </div>
                <template v-if="hasAddonFeeConfig">
                  <div v-for="fee in addonFeeConfigs" :key="`fee-${fee.id}-${fee.name}`" class="order-price__row mb-8 leading-normal indent">
                    <label class="text-sm text-grey-darkest">{{ fee.name }}</label>
                    <div class="text-right">
                      <span class="block text-sm text-grey-darkest">{{ selectedCurrency }} <big class='text-sm'>{{ fee.price | currency }}</big></span>
                      <span v-show="!isCurrencyMatchHotelCurrency" class='text-sm text-grey-dark block mt-4'>
                        ( {{ hotelHotelOriginCurrency }} {{ fee.price | currency({ outputCurrency: hotelHotelOriginCurrency }) }} )
                      </span>
                    </div>
                  </div>
                </template>

                <div class="order-price__row text-grey-darker leading-normal font-bold">
                  <label class="text-grey-darkest">{{ $t('total') }}</label>
                  <div class="text-right">
                    <span class="block text-sm text-grey-darkest">{{ selectedCurrency }} <big class="text-xl text-blue">{{ resultCommon.totalPrice | currency }}</big></span>
                    <span v-show="!isCurrencyMatchHotelCurrency" class="text-sm text-grey-dark block mt-4">
                      ( {{ hotelHotelOriginCurrency }} {{ resultCommon.totalPrice | currency({ outputCurrency: hotelHotelOriginCurrency }) }} )
                    </span>
                  </div>
                </div>
              </template>
              <!-- === -->

              <!-- 只有訂金(resultDeposit) -->
              <template v-if="hasDeposit && !isCouponAvailable">
                <div class="order-price__row mb-8 text-grey-darkest leading-normal">
                  <label class="text-sm">
                    {{ $t('roomsCost') }}
                    <small v-if="!hasPlanFeeConfig">({{ $t('room_price_has_tax_included') }})</small>
                  </label>
                  <div class="text-right">
                    <span class="block text-sm text-grey-darkest">{{ selectedCurrency }} <big class='text-sm'>{{ resultDeposit.plansTotalPrice | currency }}</big></span>
                    <span v-show="!isCurrencyMatchHotelCurrency" class='text-sm text-grey-dark block mt-4'>
                      ( {{ hotelHotelOriginCurrency }} {{ resultDeposit.plansTotalPrice | currency({ outputCurrency: hotelHotelOriginCurrency }) }} )
                    </span>
                  </div>
                </div>
                <template v-if="hasPlanFeeConfig">
                  <div v-for="fee in planFeeConfigs" :key="`fee-${fee.id}-${fee.name}`" class="order-price__row mb-8 leading-normal indent">
                    <label class="text-sm text-grey-darkest">{{ fee.name }}</label>
                    <div class="text-right">
                      <span class="block text-sm text-grey-darkest">{{ selectedCurrency }} <big class='text-sm'>{{ fee.price | currency }}</big></span>
                      <span v-show="!isCurrencyMatchHotelCurrency" class='text-sm text-grey-dark block mt-4'>
                        ( {{ hotelHotelOriginCurrency }} {{ fee.price | currency({ outputCurrency: hotelHotelOriginCurrency }) }} )
                      </span>
                    </div>
                  </div>
                </template>

                <extraReceptionPrice />

                <div v-if="hasAddons" class="order-price__row mb-8 leading-normal">
                  <label class="text-sm text-grey-darkest">{{ $t('addonsCost') }}</label>
                  <div class="text-right">
                    <span class="block text-sm text-grey-darkest">{{ selectedCurrency }} <big class='text-sm'>{{ resultDeposit.addonsTotalPrice | currency }}</big></span>
                    <span v-show="!isCurrencyMatchHotelCurrency" class='text-sm text-grey-dark block mt-4'>
                      ( {{ hotelHotelOriginCurrency }} {{ resultDeposit.addonsTotalPrice | currency({ outputCurrency: hotelHotelOriginCurrency }) }} )
                    </span>
                  </div>
                </div>
                <template v-if="hasAddonFeeConfig">
                  <div v-for="fee in addonFeeConfigs" :key="`fee-${fee.id}-${fee.name}`" class="order-price__row mb-8 leading-normal indent">
                    <label class="text-sm text-grey-darkest">{{ fee.name }}</label>
                    <div class="text-right">
                      <span class="block text-sm text-grey-darkest">{{ selectedCurrency }} <big class='text-sm'>{{ fee.price | currency }}</big></span>
                      <span v-show="!isCurrencyMatchHotelCurrency" class='text-sm text-grey-dark block mt-4'>
                        ( {{ hotelHotelOriginCurrency }} {{ fee.price | currency({ outputCurrency: hotelHotelOriginCurrency }) }} )
                      </span>
                    </div>
                  </div>
                </template>

                <div class="order-price__row mb-8 leading-normal font-bold">
                  <label class="text-sm text-grey-darkest">{{ $t('total') }}</label>
                  <div class="text-right">
                    <span class="block text-sm text-grey-darkest">{{ selectedCurrency }} {{ resultDeposit.totalPrice | currency }}</span>
                    <span v-show="!isCurrencyMatchHotelCurrency" class="text-sm text-grey-dark block mt-4 mb-16">
                      ( {{ hotelHotelOriginCurrency }} {{ resultDeposit.totalPrice | currency({ outputCurrency: hotelHotelOriginCurrency }) }} )
                    </span>
                  </div>
                </div>
                <div class="hr"></div>
                <div class="order-price__row mb-8 leading-normal">
                  <label class="text-sm text-grey-darkest">{{ $t('depositTotalWithValue', [hotelHotelDepositRate]) }}</label>
                  <div class="text-right">
                    <span class="block text-sm text-grey-darkest">{{ selectedCurrency }} <big class="text-xl text-blue font-medium">{{ resultDeposit.depositPrice | currency }}</big></span>
                    <span v-show="!isCurrencyMatchHotelCurrency" class="text-sm text-grey-dark block mt-4 mb-16">
                      ( {{ hotelHotelOriginCurrency }} {{ resultDeposit.depositPrice | currency({ outputCurrency: hotelHotelOriginCurrency }) }} )
                    </span>
                  </div>
                </div>
                <div class="order-price__row text-sm text-grey-darker leading-normal">
                  <label>{{ $t('remainTotal') }}</label>
                  <div class="text-right">
                    <span class="block text-sm text-grey-darkest">{{ selectedCurrency }} <big class="text-sm">{{ resultDeposit.remainPrice | currency }}</big></span>
                    <span v-show="!isCurrencyMatchHotelCurrency" class='text-sm text-grey-dark block mt-4'>
                      ( {{ hotelHotelOriginCurrency }} {{ resultDeposit.remainPrice | currency({ outputCurrency: hotelHotelOriginCurrency }) }} )
                    </span>
                  </div>
                </div>
              </template>
              <!-- === -->

              <!-- 只有折價券(resultCoupon) -->
              <template v-if="!hasDeposit && isCouponAvailable">
                <div class="order-price__row mb-8 leading-normal">
                  <label class="text-sm text-grey-darkest">
                    {{ $t('roomsCost') }}
                    <small v-if="!hasPlanFeeConfig">({{ $t('room_price_has_tax_included') }})</small>
                  </label>
                  <div class="text-sm text-grey-darker text-right">
                    <span class="line-through">{{ selectedCurrency }} {{ resultCoupon.plansTotalPrice | currency }}</span>
                    <span v-show="!isCurrencyMatchHotelCurrency" class='text-sm text-grey-dark block mt-4'>( {{ hotelHotelOriginCurrency }} {{ resultCoupon.plansTotalPrice | currency({ outputCurrency: hotelHotelOriginCurrency }) }} )</span>
                  </div>
                </div>
                <div class="order-price__row mb-8">
                  <div class="text-sm text-grey-darkest leading-normal">{{ $t('coupon') }}</div>
                  <div class="text-sm text-grey-darker flex">
                    <div class="text-xs text-green-darker border border-green-dark rounded-full mr-8 py-4 px-8 mr-16 self-start">{{ $t('roomTotalDiscount') }}</div>
                    <div class="text-sm text-right">
                      <span class="text-grey-darker leading-normal">-{{ selectedCurrency }} {{ resultCoupon.discountPrice | currency }}</span>
                      <span v-show="!isCurrencyMatchHotelCurrency" class="text-grey-dark block mt-4 leading-normal">
                        ( {{ hotelHotelOriginCurrency }} {{ resultCoupon.discountPrice | currency({ outputCurrency: hotelHotelOriginCurrency }) }} )
                      </span>
                    </div>
                  </div>
                </div>
                <template v-if="hasPlanFeeConfig">
                  <div v-for="fee in planFeeConfigs" :key="`fee-${fee.id}-${fee.name}`" class="order-price__row mb-8 leading-normal indent">
                    <label class="text-sm text-grey-darkest">{{ fee.name }}</label>
                    <div class="text-right">
                      <span class="block text-sm text-grey-darkest">{{ selectedCurrency }} <big class='text-sm'>{{ fee.price | currency }}</big></span>
                      <span v-show="!isCurrencyMatchHotelCurrency" class='text-sm text-grey-dark block mt-4'>
                        ( {{ hotelHotelOriginCurrency }} {{ fee.price | currency({ outputCurrency: hotelHotelOriginCurrency }) }} )
                      </span>
                    </div>
                  </div>
                </template>

                <extraReceptionPrice />

                <div v-if="hasAddons" class="order-price__row mb-8 leading-normal">
                  <div class="text-sm text-grey-darkest">{{ $t('addonsCost') }}</div>
                  <div class="text-sm text-grey-darker text-right">
                    <span class="text-grey-darkest">{{ selectedCurrency }} {{ resultCoupon.addonsTotalPrice | currency }}</span>
                    <span v-show="!isCurrencyMatchHotelCurrency" class='text-sm text-grey-dark block mt-4'>( {{ hotelHotelOriginCurrency }} {{ resultCoupon.addonsTotalPrice | currency({ outputCurrency: hotelHotelOriginCurrency }) }} )</span>
                  </div>
                </div>
                <template v-if="hasAddonFeeConfig">
                  <div v-for="fee in addonFeeConfigs" :key="`fee-${fee.id}-${fee.name}`" class="order-price__row mb-8 leading-normal indent">
                    <label class="text-sm text-grey-darkest">{{ fee.name }}</label>
                    <div class="text-right">
                      <span class="block text-sm text-grey-darkest">{{ selectedCurrency }} <big class='text-sm'>{{ fee.price | currency }}</big></span>
                      <span v-show="!isCurrencyMatchHotelCurrency" class='text-sm text-grey-dark block mt-4'>
                        ( {{ hotelHotelOriginCurrency }} {{ fee.price | currency({ outputCurrency: hotelHotelOriginCurrency }) }} )
                      </span>
                    </div>
                  </div>
                </template>

                <div class="hr"></div>
                <div class="order-price__row font-bold">
                  <label class="text-grey-darkest">{{ $t('total') }}</label>
                  <div class="text-right">
                    <span class="block text-sm text-grey-darkest">{{ selectedCurrency }} <big class='text-xl text-blue font-medium'>{{ resultCoupon.discountFinalPrice | currency }}</big></span>
                    <span v-show="!isCurrencyMatchHotelCurrency" class='text-sm text-grey-dark block mt-4'>
                      ( {{ hotelHotelOriginCurrency }} {{ resultCoupon.discountFinalPrice | currency({ outputCurrency: hotelHotelOriginCurrency }) }} )
                    </span>
                  </div>
                </div>
              </template>

              <!-- 訂金 + 折價券(resultDepositCoupon) -->
              <template v-if="hasDeposit && isCouponAvailable">
                <div class="order-price__row mb-8 leading-normal">
                  <label class="text-sm text-grey-darkest">
                    {{ $t('roomsCost') }}
                    <small v-if="!hasPlanFeeConfig">({{ $t('room_price_has_tax_included') }})</small>
                  </label>
                  <div class="text-sm text-grey-darker text-right">
                    <span class="line-through">{{ selectedCurrency }} {{ resultDepositCoupon.plansTotalPrice | currency }}</span>
                    <span v-show="!isCurrencyMatchHotelCurrency" class='text-sm text-grey-dark block mt-4'>( {{ hotelHotelOriginCurrency }} {{ resultDepositCoupon.plansTotalPrice | currency({ outputCurrency: hotelHotelOriginCurrency }) }} )</span>
                  </div>
                </div>
                <div class="order-price__row mb-8">
                  <div class="text-sm text-grey-darkest leading-normal">{{ $t('coupon') }}</div>
                  <div class="text-sm text-grey-darker flex">
                    <div class="text-xs text-green-darker border border-green-dark rounded-full mr-8 py-4 px-8 mr-16 self-start">{{ $t('roomTotalDiscount') }}</div>
                    <div class="text-sm text-right">
                      <span class="text-grey-darker leading-normal">-{{ selectedCurrency }} {{ resultDepositCoupon.discountPrice | currency }}</span>
                      <span v-show="!isCurrencyMatchHotelCurrency" class="text-grey-dark block mt-4 leading-normal">
                        ( {{ hotelHotelOriginCurrency }} {{ resultDepositCoupon.discountPrice | currency({ outputCurrency: hotelHotelOriginCurrency }) }} )
                      </span>
                    </div>
                  </div>
                </div>
                <template v-if="hasPlanFeeConfig">
                  <div v-for="fee in planFeeConfigs" :key="`fee-${fee.id}-${fee.name}`" class="order-price__row mb-8 leading-normal indent">
                    <label class="text-sm text-grey-darkest">{{ fee.name }}</label>
                    <div class="text-right">
                      <span class="block text-sm text-grey-darkest">{{ selectedCurrency }} <big class='text-sm'>{{ fee.price | currency }}</big></span>
                      <span v-show="!isCurrencyMatchHotelCurrency" class='text-sm text-grey-dark block mt-4'>
                        ( {{ hotelHotelOriginCurrency }} {{ fee.price | currency({ outputCurrency: hotelHotelOriginCurrency }) }} )
                      </span>
                    </div>
                  </div>
                </template>

                <extraReceptionPrice />

                <div v-if="hasAddons" class="order-price__row mb-8 leading-normal">
                  <div class="text-sm text-grey-darkest">{{ $t('addonsCost') }}</div>
                  <div class="text-sm text-right">
                    <span class="text-grey-darkest">{{ selectedCurrency }} {{ resultDepositCoupon.addonsTotalPrice | currency }}</span>
                    <span v-show="!isCurrencyMatchHotelCurrency" class='text-sm text-grey-dark block mt-4'>( {{ hotelHotelOriginCurrency }} {{ resultDepositCoupon.addonsTotalPrice | currency({ outputCurrency: hotelHotelOriginCurrency }) }} )</span>
                  </div>
                </div>
                <template v-if="hasAddonFeeConfig">
                  <div v-for="fee in addonFeeConfigs" :key="`fee-${fee.id}-${fee.name}`" class="order-price__row mb-8 leading-normal indent">
                    <label class="text-sm text-grey-darkest">{{ fee.name }}</label>
                    <div class="text-right">
                      <span class="block text-sm text-grey-darkest">{{ selectedCurrency }} <big class='text-sm'>{{ fee.price | currency }}</big></span>
                      <span v-show="!isCurrencyMatchHotelCurrency" class='text-sm text-grey-dark block mt-4'>
                        ( {{ hotelHotelOriginCurrency }} {{ fee.price | currency({ outputCurrency: hotelHotelOriginCurrency }) }} )
                      </span>
                    </div>
                  </div>
                </template>

                <div class="order-price__row mb-8 leading-normal font-bold">
                  <div class="text-sm text-grey-darkest">{{ $t('total') }}</div>
                  <div class="text-sm text-right">
                    <span class="text-grey-darkest">{{ selectedCurrency }} {{ resultDepositCoupon.discountFinalPrice | currency }}</span>
                    <span v-show="!isCurrencyMatchHotelCurrency" class='text-sm text-grey-dark block mt-4'>
                      ( {{ hotelHotelOriginCurrency }} {{ resultDepositCoupon.discountFinalPrice | currency({ outputCurrency: hotelHotelOriginCurrency }) }} )
                    </span>
                  </div>
                </div>
                <div class="hr"></div>
                <div class="order-price__row mb-8">
                  <label class='text-sm text-grey-darkest'>{{ $t('depositTotalWithValue', [hotelHotelDepositRate]) }}</label>
                  <div class="text-right">
                    <span class="block text-sm text-grey-darkest">{{ selectedCurrency }} <big class='text-xl text-blue font-medium'>{{ resultDepositCoupon.depositPrice | currency }}</big></span>
                    <span v-show="!isCurrencyMatchHotelCurrency" class='text-sm text-grey-dark block mt-4 mb-16'>
                      ( {{ hotelHotelOriginCurrency }} {{ resultDepositCoupon.depositPrice | currency({ outputCurrency: hotelHotelOriginCurrency }) }} )
                    </span>
                  </div>
                </div>
                <div class="order-price__row text-sm text-grey-darker mb-8">
                  <label>{{ $t('remainTotal') }}</label>
                  <div class="text-right">
                    <span class="block text-sm text-grey-darkest">{{ selectedCurrency }} <big class="text-sm">{{ resultDepositCoupon.remainPrice | currency }}</big></span>
                    <span v-show="!isCurrencyMatchHotelCurrency" class='text-sm text-grey-dark block mt-4 mb-16'>
                      ( {{ hotelHotelOriginCurrency }} {{ resultDepositCoupon.remainPrice | currency({ outputCurrency: hotelHotelOriginCurrency }) }} )
                    </span>
                  </div>
                </div>
              </template>
              <!-- === -->

              <template v-if="!isCurrencyMatchHotelCurrency">
                <span class="text-sm text-grey-dark block mt-16 leading-normal">
                  <i class="owl-status-circle-info"></i>
                  {{ $t('currencyConvertNotice', { selectedCurrency: selectedCurrency, hotelCurrency: hotelHotelOriginCurrency }) }}
                </span>
              </template>
            </div>

          </blockCard>

          <!-- 付款方式 -->
          <blockCard
            class='payment-ways'
            :title="$t('paymentType')">
            <div v-if="isOrderAllowPay" class="payment-ways__options flex flex-col flex-wrap leading-loose md:flex-row md:items-center">
              <template v-for="way in activedWays">
                <div class="payment-ways__options__option" v-if="isApplePay(way.type)" :key="way.type" @click="onSelectPaymentWay(way.type)">
                  <!-- <input type="radio" name="payment-ways" :id="`payment-ways__options__option__${way.type}`" :checked="way.type === customer.paymentWay"> -->
                  <!-- <label :for="`payment-ways__options__option__${way.type}`">{{ way.type }}</label> -->
                  <div class="owl-radio-wrap" :class="{ disabled: isSubmitting }">
                    <label :for="`payment-ways__options__option__${way.type}`">
                      <input type="radio" :id="`payment-ways__options__option__${way.type}`" :name="way.type" :checked="way.type === customer.paymentWay" :disabled="isSubmitting">
                      <div class="owl-radio"></div>
                      <template v-if="paymentWayLogo(way.type)">
                        <img :src="paymentWayLogo(way.type)" :alt="way.type" :title="$t(way.type)" v-tippy>
                      </template>
                      <template v-else>
                        <span class="uppercase" :title="$t(way.type)" v-tippy>{{ way.type.includes('atm') ? $t('atm-transfer') : $t(way.type) }}</span>
                      </template>
                    </label>
                  </div>
                </div>
              </template>
            </div>
            <div v-else class="text-orange flex items-center font-bold">
              <i class="owl-status-triangle-warning mr-8"></i>
              <span>{{ $t('payments_not_allowed') }}</span>
            </div>
            <div v-show="isInlineCreditCard" class="orderer-info border border-grey-light my-8 p-16 rounded">
              <div class="form-group w-full sm:w-1/2 sm:pr-24">
                <label class='is-required' for="cardNumber">{{ $t('cardNumber') }}</label>
                <cleave v-model="stripeCard.number"
                  :options="cardNumberOptions"
                  class="input"
                  :class="{'input-error': validation.firstError('stripeCard.number')}"
                  placeholder="**** **** **** ****"
                  :disabled="isSubmitting">
                </cleave>
                <span
                  v-if="validation.firstError('stripeCard.number')"
                  class='format-error-notify'>{{ $t('errorMsg.empty') }}</span>
              </div>
              <div class="form-group w-full sm:w-1/2 sm:pr-24">
                <label class='is-required' for="cardExpiryDate">{{ $t('cardExpiryDate') }}</label>
                <cleave v-model="stripeCard.expiryDate"
                  :options="expiryDateOptions"
                  class="input"
                  :class="{'input-error': validation.firstError('stripeCard.expiryDate')}"
                  placeholder="MM/YY"
                  :disabled="isSubmitting">
                </cleave>
              </div>
              <div class="form-group w-full sm:w-1/2 sm:pr-24">
                <label class='is-required' for="cardCvc">{{ $t('cardCvc') }}</label>
                <cleave v-model="stripeCard.cvc"
                  :options="cvcOptions"
                  class="input"
                  :class="{'input-error': validation.firstError('stripeCard.cvc')}"
                  placeholder="***"
                  :disabled="isSubmitting">
                </cleave>
              </div>
              <div v-show="customer.paymentWay.includes('paynow')" class="form-group w-full sm:w-1/2 sm:pr-24">
                <span class="text-sm text-grey-dark" v-html="$t('payNowCreditCardNote')"></span>
              </div>
            </div>

            <!-- stripe -->
            <div id="stripe-payment-form" v-show="isStripeFuturePay">
              <div class="form-row">
                <label for="stripe-card-element">Credit or debit card</label>
                <div class="flex">
                  <div class="stripe-card-element-input w-full mr-8">
                    <div id="stripe-card-element">
                      <!-- A Stripe Element will be inserted here. -->
                    </div>

                    <!-- Used to display form errors. -->
                    <div id="stripe-card-errors" role="alert"></div>
                  </div>
                </div>
              </div>
            </div>
            <!--  -->
            <div
              v-if="customer.paymentWay === 'atm'"
              class="my-16 flex items-center"
            >
              <i class="owl-status-triangle-warning text-orange text-xl mr-8"></i>
              <p class="text-grey-darkest text-sm">
                {{ $t('paymentNote.atm') }}
              </p>
            </div>
            <div class="payment-agreement__row border-t-1 border-grey-lighter pt-24">
              <div
                class="owl-checkbox-wrap"
                :class="{ error: validation.firstError('isAgreementChecked'), disabled: isSubmitting }">
                <blockCard class="bg-orange-lightest" v-if="isTaiwan && isZhTw">
                  <div class="notice flex items-center mb-12">
                    <i class="owl-status-triangle-warning text-orange text-xl mr-8"></i>
                    <span class='text-grey-darkest text-sm'>請注意</span>
                  </div>
                  <ul class="list-block">
                    <li class='leading-normal text-grey-darkest text-sm whitespace-pre-wrap'>送出後下一頁會連結至所選擇金流頁面，請耐心等候訂購成功頁面出現，請勿在金流等候頁面關閉視窗、重新整裡或點選回上頁，以免造成訂購失敗或重複付款事宜。</li>
                    <li class='leading-normal text-grey-darkest text-sm whitespace-pre-wrap'>旅宿業者或訂房系統商<span class="font-bold text-grey-darkest">不會以任何形式</span>要求您前往操作<span class="text-red-dark">ATM解除扣款、變更付款方式、補繳金額、要求轉帳、解除分期、購券刷退退款</span>等操作，亦不會要求您提供<span class="text-red-dark">信用卡帳號資料、銀行帳號相關資料</span>。若接到可疑電話，請<span class="font-bold text-grey-darkest">務必致電旅宿業者確認，或撥打警政署反詐騙諮詢專線165，請勿回撥可疑來電，提醒您務必提高警覺！</span></li>
                  </ul>
                </blockCard>
                <label for="payment-agreement">
                  <input type="checkbox" id="payment-agreement" name="payment-agreement" v-model="isAgreementChecked" :class="{ disabled: isSubmitting }">
                  <div class="owl-checkbox"></div>
                  <span>{{ $t('agreement') }}</span>
                </label>
              </div>
            </div>
            <div class="payment-agreement__row flex flex-col md:flex-row justify-between">
              <button
                class='pre-step btn border-1 md:border-none md:btn-lg text-grey flex-no-shrink w-full md:w-auto mb-16 md:mb-0'
                :disabled="isSubmitting"
                @click="preStep">{{ $t('pre') }}</button>
              <button
                class="btn md:btn-lg btn-solid btn-blue w-full md:w-auto md:px-80"
                :disabled="isSubmitButtonDisabled"
                @click="onSubmitBtnClick">
                <span v-show='!isSubmitting'>{{ $t('sendOrder') }}</span>
                <span v-show='isSubmitting'>{{ $t('processing') }}</span>
              </button>
            </div>
          </blockCard>
        </div>
      </div>
    </section>

  </main>
</template>

<script>
// @ is an alias to /src
import _ from 'lodash'
import { mapGetters, mapMutations, mapActions } from 'vuex'
import processSteps from '@/components/processSteps'
import blockCard from '@/components/blockCard'
import hotelCardOrder from '@/views/Payment/components/hotelCardOrder'
import addonCardOrder from '@/views/Payment/components/addonCardOrder'
import extraReceptionPrice from './components/extraReceptionPrice.vue'
import countries from '@/assets/countries'
import payment from '@/utils/payment'
import SmoothScroll from 'smooth-scroll'
import SimpleVueValidation from 'simple-vue-validator'
import Cleave from 'vue-cleave-component'
import fullLoading from '@/mixins/fullLoading'
import $cookies from 'js-cookie'

const Validator = SimpleVueValidation.Validator

/* eslint-disable*/
const applepay  = new OwlTingApplePay('applePayButton', 'https://www.owlting.com/business/ap/validate_hotel.php?u=')
let applepay_token
/* eslint-enable */

export default {
  name: 'Payment',
  metaInfo () {
    return {
      title: this.$t('page.payment'),
      meta: [
        {
          'property': 'og:title',
          'content': this.$t('page.payment'),
          'vmid': 'og:title'
        },
        {
          'property': 'og:site_name',
          'content': this.$t('page.payment'),
          'vmid': 'og:site_name'
        }
      ]
    }
  },
  mixins: [fullLoading],
  components: {
    processSteps,
    blockCard,
    hotelCardOrder,
    addonCardOrder,
    Cleave,
    extraReceptionPrice
  },
  data () {
    return {
      countries,
      couponCode: '',
      previewResult: {},
      tempPreviewResult: {},
      currentCountrySelect: {
        // name: 'Taiwan (台灣)',
        // iso2: 'tw',
        // diacode: '886'
      },
      customer: {
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        linId: '',
        wechatId: '',
        country: '',
        specific: '',
        paymentWay: ''
      },
      stripeCard: {
        number: '',
        expiryDate: '',
        cvc: ''
      },
      cardType: null,
      cardNumberOptions: {
        creditCard: true,
        delimiter: ' ',
        onCreditCardTypeChanged: (type) => {
          this.cardType = type
        }
      },
      expiryDateOptions: {
        date: true,
        datePattern: ['m', 'y']
      },
      cvcOptions: {
        numericOnly: true,
        blocks: [4],
        maxLength: 4
      },
      isAgreementChecked: false,
      showApplePayButton: false,
      isSubmitting: false,
      isCustomerInfoValid: false,
      isCouponValid: false, // 輸入的折扣碼是否可用
      isCouponTriggered: false, // 是否點擊過「使用」折扣碼
      isCouponChecked: false,
      couponResultErrors: [],
      isOrderCanNotApplyCoupon: true,
      shouldShowCouponCodeConditions: false,
      appliedCouponContent: {
        // name: '',
        // code: '6767',
        // discount_unit: 'percent',
        // discount_value: 5,
        // end_date: '2020-06-30',
        // exclude_holidays: false,
        // limit_at_least_price: 100,
        // limit_max_price: 1000,
        // limit_used_count: 100,
        // rooms: null,
        // start_date: '2020-06-01',
        // valid_end_date: '2020-07-31 00:00:00',
        // valid_start_date: '2020-07-01 00:00:00',
        // week_days: [0, 1, 5, 6]
      },
      couponResultText: '',
      isOrderAllowPay: true,
      couponErrorMsgsMap: {
        hotel_coupon_not_exist: this.$t('couponErrors.hotelCouponNotExist'), // 10006
        hotel_coupon_invalid_date_limit: this.$t('couponErrors.hotelCouponInvalidDateLimit'), // 80037
        hotel_coupon_reach_used_limit: this.$t('couponErrors.hotelCouponReachUsedLimit'), // 80030
        hotel_coupon_at_least_price_limit: this.$t('couponErrors.hotelCouponAtLeastPriceLimit'), // 80031
        hotel_coupon_date_limit: this.$t('couponErrors.hotelCouponDateLimit'), // 80032
        hotel_coupon_invalid_room_list_limit: this.$t('couponErrors.hotelCouponInvalidRoomListLimit'), // 80033
        hotel_coupon_invalid_plan_list_limit: this.$t('couponErrors.hotelCouponInvalidPlanListLimit'), // 80091
        hotel_coupon_week_days_limit: this.$t('couponErrors.hotelCouponWeekDaysLimit'), // 80035
        hotel_coupon_invalid_holiday_limit: this.$t('couponErrors.hotelCouponInvalidHolidayLimit'), // 80036
        hotel_coupon_invalid_collection: this.$t('couponErrors.hotelCouponInvalidCollection'), // 80038
        hotel_coupon_invalid_order_used: this.$t('couponErrors.hotelCouponInvalidOrderUsed') // 80092
      },
      stripeInstance: null,
      stripeCardElement: null,
      stripeClientSecret: '',
      isStripeCardInfoCompleted: false,
      activedWays: [],
      customerNumber: {
        adult: '',
        child: '',
        infant: ''
      }
    }
  },
  validators: {
    // 姓名接受中文、英文、空格、連字號(-)
    'customer.firstName' (value) {
      return Validator.value(value).required().regex(this.nameRegex)
    },
    'customer.lastName' (value) {
      return Validator.value(value).required().regex(this.nameRegex)
    },
    'customer.country' (value) {
      return Validator.value(value).required()
    },
    'customer.phone' (value) {
      // 接受開頭加號、數字、井字號、減號，7字以上
      return Validator.value(value).required().regex('^[\\+]?[0-9\\#\\-]{7,}$')
    },
    'customer.email' (value) {
      return Validator.value(value).required().email()
    },
    'customer.paymentWay' (value) {
      return Validator.value(value).required()
    },
    'stripeCard.number' (value) {
      if (this.isInlineCreditCard) {
        return Validator.value(value).required()
      } else {
        return false
      }
    },
    'stripeCard.expiryDate' (value) {
      if (this.isInlineCreditCard) {
        return Validator.value(value).required()
      } else {
        return false
      }
    },
    'stripeCard.cvc' (value) {
      if (this.isInlineCreditCard) {
        return Validator.value(value).required()
      } else {
        return false
      }
    },
    isAgreementChecked (value) {
      return Validator.custom(function () {
        if (!value) {
          return 'checked required !'
        }
      })
    }
    // 'customerNumber.adult' (value) {
    //   return Validator.value(value).required()
    // },
    // 'customerNumber.child' (value) {
    //   return this.hotelReceptionConfig.child.isReceive ? Validator.value(value).required() : false // 按照接待設定決定是否驗證
    // },
    // 'customerNumber.infant' (value) {
    //   return this.hotelReceptionConfig.infant.isReceive ? Validator.value(value).required() : false // 按照接待設定決定是否驗證
    // }
  },
  computed: {
    ...mapGetters({
      step: 'step',
      isSuper: 'isSuper',
      hotelId: 'hotelId',
      selectedCurrency: 'selectedCurrency',
      selectedLanguage: 'selectedLanguage',
      searchDateRange: 'search/dateRange',
      searchPeople: 'search/people',
      searchNights: 'search/nights',
      hotelHotel: 'hotel/hotel',
      hotelHotelDepositRate: 'hotel/hotelDepositRate',
      hotelHotelOriginCurrency: 'hotel/hotelOriginCurrency',
      orderPlans: 'order/plans',
      orderPlansTotal: 'order/plansTotal',
      orderAddons: 'order/addons',
      orderAddonsTotal: 'order/addonsTotal',
      hasDeposit: 'order/ifDepositRate',
      isHotelCouponActive: 'hotel/isCouponActive',
      hasExtraTaxFeeConfig: 'hotel/hasExtraTaxFeeConfig',
      orderTotalPrice: 'order/totalPrice',
      orderDepositTotal: 'order/depositTotal',
      stripeKey: 'hotel/stripeKey',
      paymentWays: 'hotel/paymentWays',
      hotelReceptionConfig: 'hotel/hotelReceptionConfig',
      maxPeopleInTotal: 'order/maxPeopleInTotal',
      sonyPaymentPublicKey: 'hotel/sonyPaymentPublicKey',
      extraReceptionPriceTotal: 'order/extraReceptionPriceTotal'
    }),
    isInlineCreditCard () {
      const payments = [
        'stripe-singlepay',
        'paynow-creditcard',
        'paynow-creditcard3d',
        'sony'
      ]
      return _.includes(payments, this.customer.paymentWay)
    },
    hotelCurrency () {
      return this.hotelHotelOriginCurrency
    },
    diffDays () {
      return this.$dayjs(this.searchDateRange.end).diff(this.$dayjs(this.searchDateRange.start), 'day')
    },
    isPrecautions () {
      return !_.isEmpty(this.hotelHotel.terms)
    },
    hasOwlTingExpAddon () {
      return this.orderAddons.some(addon => addon.items[0].type === 'owlexperience')
    },
    hasPlan () {
      return !_.isEmpty(this.orderPlans)
    },
    hasAddons () {
      return !_.isEmpty(this.orderAddons)
    },
    firstNameValidatedErrorMsg () {
      return this.validation.firstError('customer.firstName') === 'Required.'
        ? this.$t('errorMsg.empty')
        : this.$t('errorMsg.nameFormat')
    },
    lastNameValidatedErrorMsg () {
      return this.validation.firstError('customer.lastName') === 'Required.'
        ? this.$t('errorMsg.empty')
        : this.$t('errorMsg.nameFormat')
    },
    phoneValidatedErrorMsg () {
      return this.validation.firstError('customer.phone') === 'Required.'
        ? this.$t('errorMsg.empty')
        : this.$t('errorMsg.phoneFormat')
    },
    emailValidatedErrorMsg () {
      return this.validation.firstError('customer.email') === 'Required.'
        ? this.$t('errorMsg.empty')
        : this.$t('errorMsg.format')
    },
    hasTerms () {
      return !_.isEmpty(this.hotelHotel.terms)
    },
    isCurrencyMatchHotelCurrency () {
      return this.selectedCurrency === this.hotelHotelOriginCurrency
    },
    isCouponAvailable () {
      return this.isHotelCouponActive && this.isCouponValid
    },
    reverseCurrencyConvert () {
      return {
        inputCurrency: this.selectedCurrency,
        outputCurrency: this.hotelHotel.currency
      }
    },
    appliedCouponContentPriceCondition () {
      const coupon = this.appliedCouponContent
      const limit_at_least_price = this.$options.filters.numeral(coupon.limit_at_least_price)
      const discount_value = this.$options.filters.numeral(coupon.discount_value)
      const limit_max_price = this.$options.filters.numeral(coupon.limit_max_price)
      const discountText = coupon.discount_unit === 'price'
        // 折扣定額 price
        ? this.$t('couponDiscountText', { minPrice: limit_at_least_price, discountAmount: discount_value })
        // 折扣定比 percent
        : this.$t('couponDiscountPercentText', { minPrice: limit_at_least_price, discountAmount: discount_value })
      return coupon.limit_max_price === -1
        ? discountText
        // 最高折抵 XXX
        : discountText + ` (${this.$t('maximumDiscount', { price: limit_max_price })})`
    },
    appliedCouponContentDate () {
      return `${this.appliedCouponContent?.start_date} ~ ${this.appliedCouponContent?.end_date}`
    },
    appliedCouponContentDateSubNote () {
      const lang = this.selectedLanguage.toLowerCase() === 'zh_tw' ? 'zh-tw' : this.selectedLanguage.toLowerCase()
      const validWeekDays = this.appliedCouponContent?.week_days?.map(day => this.$dayjs().day(day).locale(lang).format('ddd'))
      const isExcludeHoliday = !!this.appliedCouponContent?.exclude_holiday
      let excludeText = ''
      if (validWeekDays?.length && isExcludeHoliday) {
        excludeText = `${validWeekDays.join('、')}，${this.$t('excludeHolidays')}`
      } else if (validWeekDays?.length && !isExcludeHoliday) {
        excludeText = `${validWeekDays.join('、')}`
      } else if (!validWeekDays?.length && isExcludeHoliday) {
        excludeText = this.$t('excludeHolidays')
      }
      return excludeText
    },
    appliedCouponContentRooms () {
      return this.appliedCouponContent.rooms?.length
        ? this.appliedCouponContent.rooms.map(room => room.name).join('、')
        : this.$t('allRoomsCanApply')
    },
    appliedCouponContentPlans () {
      const couponPlans = _.values(this.appliedCouponContent.plans)
      return couponPlans?.length
        ? couponPlans.map(plan => plan.name).join('、')
        : this.$t('allPlansCanApply')
    },
    resultCommon () {
      // 房費
      // 加購
      // 總價
      return {
        plansTotalPrice: this.previewResult.rooms_total.price_without_fee + this.previewResult.extras_total.price_without_fee,
        addonsTotalPrice: this.previewResult.addons_total.price_without_fee,
        totalPrice: this.previewResult.new_total
      }
    },
    resultDeposit () {
      // 房費
      // 加購
      // 總價
      // ===
      // 訂金
      // 尾款
      return {
        plansTotalPrice: this.previewResult.rooms_total.price_without_fee,
        addonsTotalPrice: this.previewResult.addons_total.price_without_fee,
        totalPrice: this.previewResult.new_total,
        depositPrice: this.previewResult.deposit_total,
        remainPrice: this.previewResult.new_total - this.previewResult.deposit_total
      }
    },
    resultCoupon () {
      // 房費
      // 折扣
      // 加購
      // 原價
      // ===
      // 折扣後總價
      return {
        plansTotalPrice: this.previewResult.rooms_total.price_without_fee,
        discountPrice: this.previewResult.rooms_total.coupon_discount,
        addonsTotalPrice: this.previewResult.addons_total.price_without_fee,
        totalPrice: this.previewResult.new_total + this.previewResult.rooms_total.coupon_discount,
        discountFinalPrice: this.previewResult.new_total
      }
    },
    resultDepositCoupon () {
      // 房費
      // 折扣
      // 加購
      // 原價
      // 折扣後總價
      // ===
      // 訂金
      // 尾款
      return {
        plansTotalPrice: this.previewResult.rooms_total.price_without_fee,
        discountPrice: this.previewResult.rooms_total.coupon_discount,
        addonsTotalPrice: this.previewResult.addons_total.price_without_fee,
        totalPrice: this.previewResult.new_total + this.previewResult.rooms_total.coupon_discount,
        discountFinalPrice: this.previewResult.new_total,
        depositPrice: this.previewResult.deposit_total,
        remainPrice: this.previewResult.new_total - this.previewResult.deposit_total
      }
    },
    isStripeFuturePay () {
      return this.customer.paymentWay === 'stripe-futurepay'
    },
    isStripeFuturePayValid () {
      return !!this.stripeClientSecret
    },
    isSubmitButtonDisabled () {
      return this.isSubmitting || !this.isOrderAllowPay || (this.isStripeFuturePay && !this.isStripeCardInfoCompleted)
    },
    extraFees () {
      return this.previewResult.order_items?.reduce((sum, item) => {
        item.fee_configs.forEach(feeConfig => {
          const feeItem = sum.find(fee => fee.name === feeConfig.name)
          if (feeItem) {
            feeItem.price += feeConfig.addition_total
          } else {
            sum.push({
              id: feeConfig.id,
              name: feeConfig.name,
              price: feeConfig.addition_total
            })
          }
        })
        return sum
      }, [])
    },
    hasStripeFuturePayment () {
      return this.activedWays.find(payment => payment.type === 'stripe-futurepay')
    },
    hasPlanFeeConfig ({ previewResult }) {
      return previewResult.rooms_total?.fee_configs?.length
    },
    planFeeConfigs ({ previewResult }) {
      return previewResult.rooms_total?.fee_configs
    },
    hasAddonFeeConfig ({ previewResult }) {
      return previewResult.addons_total?.fee_configs?.length
    },
    addonFeeConfigs ({ previewResult }) {
      return previewResult.addons_total?.fee_configs
    },
    adultOption ({ maxPeopleInTotal }) {
      return Array.from(Array(maxPeopleInTotal - this.customerNumber.child).keys(), value => value + 1) // 成人至少1人
    },
    childOption ({ maxPeopleInTotal }) {
      return Array.from(Array(maxPeopleInTotal + 1 - this.customerNumber.adult).keys())
    },
    infantOption () {
      return Array.from(Array(11).keys())
    },
    nameRegex () {
      return '^[^0-9~!@#$%^&*+`:;="|?<>,{}\\[\\]\\\\/]+$'
    },
    isTaiwan: function () {
      return this.hotelHotel.country.toLowerCase() === 'tw'
    },
    isZhTw: function () {
      return this.selectedLanguage.toLowerCase() === 'zh_tw'
    },
    errorListForI18n () {
      return {
        '20000': this.$t('creditcardInvalidFormat'),
        '90000': this.$t('unknownError')
      }
    },
    hasSonyPayment () {
      return this.activedWays.some(payment => payment.type === 'sony')
    }
  },
  methods: {
    ...mapMutations([
      'SET_STEP',
      'order/SET_CUSTOMER',
      'order/SET_STRIPE_CARD',
      'order/SET_APPLE_PAY_TOKEN',
      'order/SET_PREVIEW_RESULT',
      'order/CLEAR_ORDERS',
      'order/SET_CUSTOMER_NUMBER',
      'SET_COVER_LOADING',
      'order/SET_SONY_PAYMENT_TOKEN'
    ]),
    ...mapActions([
      'hotel/fetchRooms',
      'order/couponPreview',
      'order/createOrder',
      'hotel/fetchHotelPaymentWays',
      'hotel/fetchSonyPaymentPublicKey'
    ]),
    getActivedWays () {
      // applepay
      // atm
      // intella-linepay
      // intella-wechatpay
      // creditcard
      // paypal
      // unionpay
      // taiwan-travel 國旅卡
      // sony payment
      // pay-at-hotel
      let activatedPayments = this.paymentWays

      let finalPayments = []
      // 如果有選擇 奧丁丁體驗 => 隱藏 atm-hotel和pay-at-hotel
      if (this.hasPlan && this.hasOwlTingExpAddon) {
        const hiddenPaymentList = ['atm-hotel', 'applepay', 'pay-at-hotel']
        const paymentForExpAddon = activatedPayments.filter(payment => !hiddenPaymentList.includes(payment.type))
        if (paymentForExpAddon.length > 0) {
          this.isOrderAllowPay = true
          const incompatibleList = ['atm-hotel', 'pay-at-hotel']
          finalPayments = activatedPayments.filter(payment => !incompatibleList.includes(payment.type))
        } else {
          this.isOrderAllowPay = false
        }
      } else {
        this.isOrderAllowPay = activatedPayments.length > 0
        finalPayments = activatedPayments
      }

      // TODO:
      // 若旅館幣別非日幣應隱藏sony payment

      this.activedWays = finalPayments
    },
    isApplePay (type) {
      if (type === 'applepay') {
        return this.showApplePayButton
      } else {
        return true
      }
    },
    isApplePaySupport () {
      /* eslint-disable*/
      if (applepay.isCanMakePayment()) {
        this.showApplePayButton = true
      }
      const that = this
      applepay.event_SendApplepay = function(token) {
        // console.debug('[ApplePay] token', token)
        that['order/SET_APPLE_PAY_TOKEN'](token)
        that.submitForm()
      }
      applepay.event_CancelApplepay = function() {
        that.handleSubmitStatus(false)
        console.debug('[ApplePay] Cancelled')
      }
      /* eslint-enable */
    },
    paymentWayLogo (type) {
      for (const format of ['png', 'jpg', 'svg']) {
        try {
          require(`@/assets/img/payment-logo/logo-${type}.${format}`)
        } catch (err) {
          // console.debug('paymentWayLogo', err)
          return false
        }
        return require(`@/assets/img/payment-logo/logo-${type}.${format}`)
      }
    },
    onSelectPaymentWay (way) {
      this.customer.paymentWay = way
    },
    selectCountry (countrySelected) {
      this.customer.country = countrySelected.iso2
    },
    preStep () {
      const hasAddons = !_.isEmpty(this.orderAddons)
      if (hasAddons) {
        this.$router.push({
          name: 'Addon',
          params: {
            hotelId: this.hotelId
          }
        })
      } else {
        this.$router.push({
          name: 'Hotel',
          params: {
            hotelId: this.hotelId
          }
        })
      }
    },
    onCouponSubmit () {
      this.isCouponTriggered = true
      this.couponResultErrors = []
      this.appliedCouponContent = {}
      this.shouldShowCouponCodeConditions = false

      if (!this.couponCode) {
        this.couponResultErrors = [this.$t('couponCanNotBeEmpty')]
        this.couponCodeResult = {}
      } else {
        this['order/couponPreview'](this.couponCode).then(res => {
          if (res.status === 200) {
            if (res.data.status === 0) {
              this.isCouponValid = true
              this.$set(this, 'previewResult', res.data.data)
              const coupon = {
                code: this.couponCode,
                result: this.previewResult
              }
              this['order/SET_PREVIEW_RESULT'](coupon)
            } else {
              this.isCouponValid = false
              this.$set(this, 'previewResult', this.tempPreviewResult)
              console.error('[COUPON ERROR]', res.data.status, res.data.msg)
              this.handleCouponErrors(res.data)
            }
          } else {
            this.$modal.show('dialog', {
              title: `<span class="text-lg">${this.$t('couponErrors.defaultMsg')}</span>`,
              buttons: [
                {
                  title: this.$t('close')
                }
              ]
            })
          }
        }).catch(err => {
          console.debug(err)
        }).finally(() => {
          this.isCouponTriggered = true
        })
      }
    },
    handleCouponErrors (error) {
      /* 錯誤權重:
        1. 折扣碼錯誤('hotel_coupon_not_exist') 10006
        2. 折扣券已過期, 折扣券尚未啟用('hotel_coupon_invalid_date_limit') 80037
        3. 折扣券已兌換完畢('hotel_coupon_reach_used_limit') 80030
        4. 折扣券未啟用，如有疑問請聯繫 XXXXXXXX(回傳 'hotel_coupon_not_exist' ?)
        5. others -> 顯示折扣券內容
      */
      if (error.msg === 'hotel_coupon_not_exist') {
        this.couponResultErrors = [this.$t('couponErrors.hotelCouponNotExist')]
      } else {
        const errorMsgsKeys = Object.keys(this.couponErrorMsgsMap) // ['hotel_coupon_invalid_date_limit', 'hotel_coupon_reach_used_limit', ...]
        const couponErrors = error.data.invalid_reasons.map(reason => reason.message) // ['hotel_coupon_invalid_date_limit', 'hotel_coupon_reach_used_limit', ...]
        let matchError

        for (let i = 0; i < couponErrors.length; i++) {
          matchError = errorMsgsKeys.find(errKey => errKey === couponErrors[i])
          if (matchError) break
        }

        this.appliedCouponContent = error.data?.coupon
        this.shouldShowCouponCodeConditions = true

        if (matchError) {
          this.couponResultErrors = [this.couponErrorMsgsMap[matchError]]
        } else {
          this.couponResultErrors = [this.$t('couponErrors.hotelCouponInvalidCollection')]
        }
      }
    },
    submitForm () {
      this.handleSubmitStatus(true)
      this['order/createOrder']()
        .then(res => {
          if (res.status === 200) {
            if (res.data.status === 0) {
              if (this.customer.paymentWay === 'applepay') {
                applepay.completePaymentSessionSuccess()
              }
              if (!this.validateLinepayPayload(res.data.data)) { // linepay payload驗證錯誤
                this.handleSubmitStatus(false)
                // eslint-disable-next-line
                this.handleError('payFailed')
              } else {
                payment(res.data.data)
                  .then(clientScret => { // for stripe future pay process
                    if (this.isStripeFuturePay && clientScret) {
                      this.stripeClientSecret = clientScret
                      this.onSubmitStripeFuturePayCard()
                    } else {
                      this.handleSubmitStatus(false)
                    }
                  })
                  .catch(error => {
                    this.handleSubmitStatus(false)
                    this.handleError(error.message)
                  })
              }
            } else {
              this.handleSubmitStatus(false)
              if (this.customer.paymentWay === 'applepay') {
                applepay.completePaymentSessionFail()
              }
              if (Object.keys(this.errorListForI18n).includes(res.data.status.toString())) {
                return this.handleError(res.data.status.toString())
              }
              this.handleError(res.data.msg)
            }
          } else if (res.status === 80047) { // 購物車內的專案有價格限制並阻擋訂單成立，清空購物車回第一頁
            this.handleError(res.data.msg, this.goFirstPage)
          } else {
            this.handleSubmitStatus(false)
            if (this.customer.paymentWay === 'applepay') {
              applepay.completePaymentSessionFail()
            }
            this.handleError(res.data.msg)
          }
        }).catch(err => {
          this.handleError(err.message)
          this.handleSubmitStatus(false)
        })
    },
    doApplePay () {
      this.handleSubmitStatus(true)
      let total = ''
      const integerCurrency = ['TWD', 'JPY']
      const isIntegerCurrencyHotel = integerCurrency.includes(this.hotelHotelOriginCurrency)
      if (this.hasDeposit) {
        const orderDepositTotal = this.previewResult.deposit_total
        total = isIntegerCurrencyHotel
          ? this.$numeral(Math.round(orderDepositTotal)).value()
          : this.$numeral(orderDepositTotal).value()
      } else {
        const orderTotal = this.previewResult.new_total
        total = isIntegerCurrencyHotel
          ? this.$numeral(Math.round(orderTotal)).value()
          : this.$numeral(orderTotal).value()
      }
      console.debug('Apple Pay Total', total)
      console.debug('typeof Total', typeof total)
      const applePayForm = {
        currency: this.hotelHotel.currency,
        country: _.toUpper(this.hotelHotel.country),
        name: this.hotelHotel.name,
        total: total
      }
      const result = applepay.sendIfTokenValid(applepay_token, applePayForm.currency, applePayForm.country, applePayForm.name, applePayForm.total)
      console.debug('[APPLE PAY] result', result)
    },
    handleSubmitStatus (bool = false) {
      this.isSubmitting = bool
      if (this.stripeCardElement) this.stripeCardElement.update({ disabled: bool })
    },
    onSubmitBtnClick: _.throttle(function () {
      if (!this.isOrderAllowPay) return false
      if (this.isStripeFuturePay && !this.isStripeCardInfoCompleted) return false
      this.$validate()
        .then(success => {
          if (success) {
            // set paymemnt flag for gtag
            const oneHour = 1 / 24
            $cookies.set('gtag-payment-flag', this.hotelHotel.hotel_uuid, { expires: oneHour })

            switch (this.customer.paymentWay) {
              case 'applepay':
                this.doApplePay()
                break
              case 'sony':
                this.doSonyPay()
                break
              default:
                this.submitForm()
                break
            }
          } else {
            var scroll = new SmoothScroll()
            const errorNode = document.querySelector('.input-error') || document.querySelector('.selector-error')
            scroll.animateScroll(
              errorNode,
              {
                // toggle
              }, {
                offset: 50
              }
            )
            return false
          }
        })
    }, 800),
    couponDiscountText (coupon) {
      const currency = this.hotelHotel.currency
      const discountValue = coupon.discount_value
      const minPrice = coupon.limit_at_least_price
      if (coupon.discount_unit === 'percent') {
        return this.$t('couponDiscountText', { minPrice: `${currency} ${minPrice}`, discountAmount: `${discountValue}%` })
      } else {
        return this.$t('couponDiscountText', { minPrice: `${currency} ${this.$options.filters.numeral(minPrice)}`, discountAmount: `${currency} ${this.$options.filters.numeral(discountValue)}` })
      }
    },
    removeCoupon () {
      this.couponResultErrors = []
      this.isCouponChecked = false
      this.isCouponValid = false
      this.isCouponTriggered = false
      this.fetchOrderPreview()
    },
    fetchOrderPreview (cb) {
      this['order/couponPreview']('')
        .then(res => {
          if (res.data.status === 0) {
            this.$set(this, 'previewResult', res.data.data)
            this.$set(this, 'tempPreviewResult', res.data.data)

            const coupon = {
              code: '',
              result: this.previewResult
            }
            this['order/SET_PREVIEW_RESULT'](coupon)

            if (cb) { cb() }
          }
        })
    },
    initStripe () {
      this.$nextTick(() => {
        // eslint-disable-next-line
        this.stripeInstance = Stripe(this.stripeKey)

        const stripeLocaleMap = {
          en: 'en',
          ja: 'ja',
          zh_TW: 'zh-TW'
        }
        const elements = this.stripeInstance.elements({
          locale: stripeLocaleMap[this.selectedLanguage] || 'auto'
        })

        this.stripeCardElement = elements.create('card', {
          iconStyle: 'solid',
          style: {
            base: {
              fontWeight: 400,
              fontFamily: 'Helvetica Neue, Helvetica, Arial, sans-serif',
              fontSize: '1rem',
              fontSmoothing: 'antialiased',

              '::placeholder': {
                color: '#5C656B'
              },
              ':-webkit-autofill': {
                color: '#078ABC'
              }
            },
            invalid: {
              iconColor: '#EA5E2E',
              color: '#EA5E2E'
            }
          }
        })

        setTimeout(() => {
          this.stripeCardElement.mount('#stripe-card-element')
        }, 100)

        this.stripeCardElement.on('change', evt => {
          this.isStripeCardInfoCompleted = evt.complete
        })
      })
    },
    async onSubmitStripeFuturePayCard () {
      const { setupIntent, error } = await this.stripeInstance.confirmCardSetup(
        this.stripeClientSecret,
        {
          payment_method: {
            card: this.stripeCardElement,
            billing_details: {
            }
          }
        }
      )

      this.stripeClientSecret = ''
      if (error) {
        // Display error.message in your UI.
        console.error('STRIPE CONFIRM CARD ERROR: ', error)
        this.handleError(this.$t('stripeErrorButOrderCreated'), this.goOrderCompletePage)
        this.handleSubmitStatus(false)
      } else {
        this.handleSubmitStatus(false)
        if (setupIntent.status === 'succeeded') {
          // The setup has succeeded. Display a success message. Send
          // setupIntent.payment_method to your server to save the card to a Customer
          this.goOrderCompletePage()
        }
      }
    },
    goOrderCompletePage () {
      const orderSerial = this.$store.state.order.paymentResponse.order_serial
      this.$router.push({
        name: 'Complete',
        params: {
          hotelId: this.hotelId,
          orderSerial: orderSerial
        }
      })
    },
    handleError (msg, cb) {
      this.$modal.show('dialog', {
        title: `<i class="owl-sstatus-triangle-warning text-xl text-orange"></i> <span class="text-xl text-orange">Payment Error</span>`,
        text: this.getErrorMsg(msg),
        buttons: [
          {
            title: this.$t('close'),
            handler: () => {
              this.$modal.hide('dialog')
              this.handleSubmitStatus(false)

              if (cb && typeof cb === 'function') cb()
            }
          }
        ]
      })
    },
    getErrorMsg (msg) {
      if (this.couponErrorMsgsMap[msg]) {
        return this.couponErrorMsgsMap[msg]
      }

      if (this.errorListForI18n[msg]) {
        return this.errorListForI18n[msg]
      }

      console.error('ERROR MESSAGE NOT FOUND: ', msg)
      return this.$t('paymentErrorDefault')
    },
    goFirstPage () {
      this['order/CLEAR_ORDERS']()
      this['hotel/fetchRooms']()
      this.$router.push({
        name: 'Hotel'
      })
    },
    resetCustomNumber () {
      this.customerNumber.adult = ''
      this.customerNumber.child = ''
      this.customerNumber.infant = ''
    },
    validateLinepayPayload (data) {
      // eslint-disable-next-line
      return data.payment_type !== 'intella-linepay' || (data.payment_payload?.Data?.qrcode_url && data.payment_payload?.Data?.urlToken)
    },
    doSonyPay () {
      this.handleSubmitStatus(true)
      const rapp = window.rappJs(this.sonyPaymentPublicKey)
      Promise.race([
        rapp.createToken({
          card: {
            card_number: this.stripeCard.number,
            exp_year: this.stripeCard.expiryDate.substring(2),
            exp_month: this.stripeCard.expiryDate.substring(0, 2),
            cvv: this.stripeCard.cvc
          }
        }),
        new Promise((resolve, reject) => {
          setTimeout(() => {
            reject(new Error('Timeout'))
          }, 1000 * 10)
        })
      ]).then(tokenInfo => {
        const { token } = tokenInfo
        this['order/SET_SONY_PAYMENT_TOKEN'](token)
        this.submitForm()
      }).catch(() => {
        this.handleSubmitStatus(false)
        this.handleError('payFailed')
      })
    },
    fetchSonyPaymentScript () {
      // 尚未載入cdn
      if (!document.getElementById('rapp')) {
        const scriptTag = document.createElement('script')
        scriptTag.src = process.env.VUE_APP_SONY_PAYMENT_TOKEN_JS
        scriptTag.id = 'rapp'
        document.getElementsByTagName('head')[0].appendChild(scriptTag)
      }
    }
  },
  activated () {
    this.fetchOrderPreview(() => {
      if (this.couponCode) {
        this.onCouponSubmit()
      } else {
        // enter coupon code automatically from url query
        const urlCouponCode = this.$route.query.coupon_code
        if (urlCouponCode && !this.previewResult?.coupons?.length) {
          this.couponCode = urlCouponCode
          this.onCouponSubmit()
        }
      }
    })

    this['hotel/fetchHotelPaymentWays']()
      .then(res => {
        this.getActivedWays()
        this.initStripe()
        if (this.hasSonyPayment) {
          this['hotel/fetchSonyPaymentPublicKey']()
          this.fetchSonyPaymentScript()
        }
      })

    // set default country
    this.currentCountrySelect =
      this.countries?.find(country => country.iso2 === this.$store?.state?.customerCountry) ||
      {
        name: 'Taiwan (台灣)',
        iso2: 'tw',
        diacode: '886'
      }

    this.customer.country = this.currentCountrySelect?.iso2

    this.isApplePaySupport()
    if (this.activedWays.length > 0) {
      this.customer.paymentWay = this.customer.paymentWay !== ''
        ? this.customer.paymentWay
        : this.activedWays[0].type !== 'applepay'
          ? this.activedWays[0].type
          : this.showApplePayButton
            ? this.activedWays[0].type
            : this.activedWays[1].type
    }

    this.isSubmitting = false
    if (!this.isSuper && _.isEmpty(this.orderPlans)) {
      this.$router.replace({
        name: 'Hotel',
        params: {
          hotelId: this.hotelId
        }
      })
    } else {
      this['SET_STEP'](3)
    }
  },
  mounted () {
    // if (this.hasStripeFuturePayment && this.stripeKey) this.initStripe()
  },
  watch: {
    stripeCard: {
      handler () {
        this['order/SET_STRIPE_CARD'](this.stripeCard)
      },
      deep: true,
      immediate: true
    },
    customer: {
      handler () {
        this['order/SET_CUSTOMER'](this.customer)
      },
      deep: true,
      immediate: true
    },
    customerNumber: {
      handler (val) {
        this['order/SET_CUSTOMER_NUMBER'](val)
      },
      deep: true,
      immediate: true
    },
    couponCode (newVal, oldVal) {
      this.couponResultErrors = []
      this.isCouponTriggered = false
      this.shouldShowCouponCodeConditions = false
      if (newVal !== oldVal) {
        this.isCouponChecked = false
        this.isCouponValid = false
        this.previewResult = this.tempPreviewResult
      }
    },
    isSubmitting (bool) {
      this.SET_COVER_LOADING(bool)
    },
    maxPeopleInTotal () {
      this.resetCustomNumber()
    }
  }
}
</script>

<style lang="sass" scoped>
.hr
  @apply border-b-1 border-grey-lighter mb-12

.block-card
  &:last-of-type
    // @apply mb-32
  @screen lg
    &:last-of-type
      @apply mb-80

.orderer-info
  .form-group
    @apply relative
    &:not(:last-of-type)
      @apply mb-24
    label
      @apply block text-grey-darker mb-16
      &.is-required
        &::before
          @apply text-red
          content: '* '
    input
      width: 100%
      // max-width: 350px
    .format-error-notify
      @apply mt-4 block text-sm text-red
    textarea
      min-hight: 200px
      resize: none
    .specific-request__note
      i
        color: #f3cf13

.order-price
  &__row
    @apply flex items-start justify-between
    &.indent
      @apply ml-16
  &__coupon-code
    // @apply border-b border-grey-lightest pb-16 mb-16
    &__input-group
      width: 260px
      flex-shrink: 0
    .coupon-input
        @apply w-full flex
        input
          @apply w-full
        button
          @apply flex-no-shrink
    .owl-status-error
      width: 20px
      height: 20px
      text-align: center
      line-height: 20px
    .applied-coupon
      border-left: solid 8px #a9d341
      @apply py-8 px-12 leading-normal relative
      &__close
        @apply absolute text-sm text-grey cursor-pointer
        right: 8px
        top: 8px
        &:hover
          @apply text-grey-dark

    &__all-content
      @apply px-16 py-12 bg-orange-lightest rounded
      &__body
        &__row
          @apply flex items-start text-sm text-grey-darker
          &:not(:last-of-type)
            @apply mb-8
          label
            @apply flex-no-shrink
            width: 80px
          span
            word-break: break-word

.payment-ways
  &__options__option
    @apply flex items-center cursor-pointer
    input
      @apply mr-12
    label
      @apply cursor-pointer
    &:not(:last-of-type)
      @apply mb-24
      @screen md
        @apply mr-40 mb-0
    img
      height: 24px

.payment-agreement__row
  @apply items-center mt-24
  input
    @apply mr-16

.precautions
  h6
    @apply text-grey-dark
  p
    @apply text-grey-darkest
.custom-blue-input-btn
  @apply bg-blue text-white #{!important}
  &:hover
    @apply bg-blue-dark #{!important}
.list-block
  padding-inline-start: 28px
ul
  list-style: none
li
  margin-bottom: 8px
ul li::before
  content: "\2022"
  color: #EA5E2E
  font-weight: bold
  display: inline-block
  width: 1em
  margin-left: -1em
</style>

<style lang="sass">
#stripe-payment-form
  @apply bg-grey-lightest rounded p-16 my-16
  label[for=stripe-card-element]
    @apply block mb-12 text-grey-darkest font-bold
  .StripeElement
    box-sizing: border-box
    height: 40px
    padding: 10px 12px
    border: 1px solid transparent
    border-radius: 4px
    background-color: white
    box-shadow: 0 1px 3px 0 #e6ebf1
    -webkit-transition: box-shadow 150ms ease
    transition: box-shadow 150ms ease
  #stripe-card-element
    @apply bg-white shadow

  .StripeElement--invalid
    @apply border-red

  .StripeElement--webkit-autofill
    background-color: #fefde5 !important

  .__PrivateStripeElement
    height: 40px
    overflow: hidden
.customer-number-label
  margin-bottom: 0 !important
  &__description
    padding: 2px 0 0 8px
.selector-error
  .multiselect__tags
    border: 1px solid #ff9884
    background: #fff6f5
.selector-label
  // width: 40%
  margin-bottom: 4px
  // padding: 8px 0 0 8px
</style>
