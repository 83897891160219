import router from '@/router'
import store from '@/store'

function checkPayloadVaild (data, rules) {
  let validResults = []
  rules.forEach(rule => {
    const result = rule(data)
    validResults.push(result)
  })
  const errorList = validResults.filter(item => typeof item === 'string')
  if (errorList.length > 0) {
    const error = new Error(errorList)
    console.error(error)
    throw error
  }
}

const hasPaymentType = (data) => !!data.payment_type || 'hasPaymentType'
const hasOrderSerial = (data) => !!data.order_serial || 'hasOrderSerial'
const hasPaymentPayload = (data) => !!data.payment_payload || 'hasPaymentPayload'
const hasRedirectUrlAndTransactionHash = (data) => (!!data.payment_payload?.payment_redirect_url && !!data.payment_payload?.transaction_hash) || 'hasRedirectUrlAndTransactionHash'
const hasUrl = (data) => !!data.payment_payload?.url || 'hasUrl'
const hasXml = (data) => !!data.payment_payload?.xml || 'hasXml'

export default function (params) {
  return new Promise((resolve, reject) => {
    try {
      const {
        payment_payload: paymentPayload,
        payment_type: paymentType,
        order_serial: orderSerial
      } = params
      const hotelId = store.state.hotel.hotel.hotel_uuid
      const body = document.body

      checkPayloadVaild(
        params,
        [hasPaymentType]
      )

      store.commit('order/SET_PAYMENT_RESPONSE', params)

      switch (paymentType) {
        case 'applepay':
        case 'stripe-singlepay':
        case 'paynow-creditcard':
        case 'atm':
        case 'atm-hotel':
        case 'pay-at-hotel':
        case 'sony':
          checkPayloadVaild(
            params,
            [hasOrderSerial]
          )
          router.push({
            name: 'Complete',
            params: {
              hotelId: hotelId,
              orderSerial: orderSerial
            }
          })
          break

        case 'paynow-creditcard3d':
          // paynow告知免3D驗證的流程：
          // 得到complete_redirect_url時，會略過既有流程，直接依照此url作轉址
          if (paymentPayload.complete_redirect_url) {
            window.location.href = paymentPayload.complete_redirect_url
            break
          }

          // 既有流程：透過payment_redirect_url告知paynow結果
          // 再由paynow redirect complete頁
          checkPayloadVaild(
            params,
            [hasPaymentPayload, hasRedirectUrlAndTransactionHash]
          )
          const {
            payment_redirect_url: paymentRedirectUrl,
            transaction_hash: transactionHash
          } = paymentPayload

          body.insertAdjacentHTML('afterend', `
            <form id="cform" action="${paymentRedirectUrl}" method="post">
              <input type="hidden" name="transaction_hash" value=${transactionHash}>
            </form>
          `)
          document.getElementById('cform').submit()
          break

        case 'creditcard':
        case 'unionpay':
        case 'taiwan-travel':
          checkPayloadVaild(
            params,
            [hasPaymentPayload, hasUrl, hasXml]
          )
          const xml = JSON.parse(JSON.stringify(paymentPayload.xml))
          body.insertAdjacentHTML('afterend', `
            <form id="cform" action="${paymentPayload.url}" method="post">
              <input type="hidden" id="cxml" name="strRqXML" value='${xml}'>
            </form>
          `)
          document.getElementById('cform').submit()
          break

        case 'intella-linepay':
        case 'intella-wechatpay':
        case 'intella-alipay':
          router.push({
            name: 'PaymentCallback',
            params: {
              hotelId: hotelId
            }
          })
          break

        case 'paypal':
        case 'molpay':
          checkPayloadVaild(
            params,
            [hasUrl]
          )
          window.location.href = paymentPayload.url
          break

        case 'stripe-futurepay':
          checkPayloadVaild(
            params,
            [hasPaymentPayload]
          )
          resolve(paymentPayload)
      }
      resolve()
    } catch (error) {
      reject(new Error('payFailed'))
    }
  })
}
