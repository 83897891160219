var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "hotel-card-order" }, [
    _c("div", { staticClass: "md:flex" }, [
      _c("figure", {
        staticClass:
          "block hotel-card-order__cover mr-16 rounded-sm bg-cover bg-center bg-no-repeat",
        style: "background-image: url('" + _vm.mapRoomCover + "')"
      }),
      _c(
        "div",
        { staticClass: "hotel-card-order__content flex flex-col w-full" },
        [
          _c("h5", { staticClass: "mb-8 text-grey-darkest font-medium" }, [
            _vm._v(_vm._s(_vm.plan.roomName))
          ]),
          _c("p", { staticClass: "text-sm text-grey-dark mb-8 md:mb-auto" }, [
            _vm._v(_vm._s(_vm._f("contentParser")(_vm.plan.planTitle)))
          ]),
          _c(
            "div",
            {
              staticClass:
                "hotel-card-order__content__row flex flex-col md:flex-row md:items-center justify-between"
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "hotel-card-order__content__detail__amount flex w-full justify-between md:text-right"
                },
                [
                  _c(
                    "div",
                    { staticClass: "text-grey-darkest text-xs md:w-1/2" },
                    [
                      _c("div", { staticClass: "text-sm text-left flex" }, [
                        _c("div", [
                          _vm._v(
                            "x " +
                              _vm._s(
                                _vm.$t("orderUnit", {
                                  stock: _vm.plan.qty,
                                  unit: _vm.plan.unit
                                })
                              )
                          )
                        ]),
                        _vm.extraReceptionTotalCount > 0
                          ? _c("div", [
                              _vm._v(
                                _vm._s(
                                  ", " +
                                    _vm.$t("extraReception.total") +
                                    "+ " +
                                    _vm.extraReceptionTotalCount +
                                    _vm.$t("person")
                                )
                              )
                            ])
                          : _vm._e()
                      ])
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "text-grey-darkest text-xs md:w-1/3 lg:w-1/5 flex-grow text-right"
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "flex justify-end items-center items-center"
                        },
                        [
                          _c("p", { staticClass: "text-sm font-bold" }, [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.selectedCurrency) +
                                " " +
                                _vm._s(_vm._f("currency")(_vm.planTotal)) +
                                "\n              "
                            )
                          ]),
                          _c(
                            "span",
                            {
                              staticClass:
                                "ml-8 text-grey-dark underline font-normal text-xs cursor-pointer",
                              on: { click: _vm.handleShowDetail }
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.$t("detail")) +
                                  "\n              "
                              )
                            ]
                          )
                        ]
                      ),
                      _vm.hasExtraTaxFeeConfig
                        ? _c(
                            "small",
                            { staticClass: "block mt-4 text-grey-dark" },
                            [_vm._v("(" + _vm._s(_vm.$t("exclude_tax")) + ")")]
                          )
                        : _c(
                            "small",
                            { staticClass: "block mt-4 text-grey-dark" },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("room_price_has_tax_included"))
                              )
                            ]
                          )
                    ]
                  )
                ]
              )
            ]
          )
        ]
      )
    ]),
    _c(
      "div",
      { staticClass: "mt-16 collapseBlock", class: { open: !_vm.isMask } },
      [
        _c(
          "h6",
          {
            staticClass: "mb-8",
            on: {
              click: function($event) {
                _vm.isMask = !_vm.isMask
              }
            }
          },
          [
            _c("i", { staticClass: "owl-caret-right" }),
            _vm._v(_vm._s(_vm.$t("cancelPolicy")))
          ]
        ),
        _c("div", { staticClass: "collapseBlock__body" }, [
          _c("p", {
            staticClass:
              "text-sm text-grey-darker leading-normal whitespace-pre-wrap",
            domProps: {
              innerHTML: _vm._s(
                _vm.$options.filters.contentParser(_vm.cancelPolicyContent)
              )
            }
          }),
          _c("div", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isMask,
                expression: "isMask"
              }
            ],
            staticClass: "collapseBlock__body__mask",
            on: {
              click: function($event) {
                _vm.isMask = false
              }
            }
          })
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }