<template>
  <div class='item-detail rounded-lg'>
    <div class='item-detail__header'>
      <div>{{ title }}</div>
      <div @click="handleClose" class="cursor-pointer">
        <i class="owl-status-error"></i>
      </div>
    </div>
    <!-- room -->
    <div class='item-detail__content' v-if="type === 'room'">
      <RoomDetail
        v-for="count in plan.qty"
        :key="`${plan.uid}_${count}`"
        :index="count - 1"
        :plan="plan"
      />
      <div class="flex justify-between my-8">
        <span>{{ `${$t('total')} (${$t('room_price_has_tax_included')})` }}</span>
        <span class="font-bold">{{ selectedCurrency }} {{ totalPrice | currency }}</span>
      </div>
    </div>
    <!-- addon -->
    <div class='item-detail__content' v-if="type === 'addon'">
      <h5 class="text-grey-darker font-medium mb-16">{{ addonName }}</h5>
      <div
        class="flex justify-between text-base"
        v-for='(item, index) in addon.items'
        :key="addon.id + '-' + $dayjs(item.date).format('YYYY-MM-DD') + '-' + item.session + '-' + index"
      >
        <h6>
          <span>
            {{ displayDate(item) }}
          </span>
          <span v-show="!isGoods(item)">
            {{ displayTime(item) }}
          </span>
        </h6>
        <h6 class="text-right w-1/2">
          <span class='mr-8'>{{ selectedCurrency }} {{ item.price_without_fee | currency({ inputCurrency: item.currency, isBonus: true }) }}</span>
          <span class='mr-8'>x {{ item.qty }} {{ item.unit }}</span>
        </h6>
        <h6>
          <span>{{ `${selectedCurrency} ${addonRowTotal(item)}` }}</span>
          <small v-if="hasExtraTaxFeeConfig" class="block mt-4 text-right">({{ $t('exclude_tax') }})</small>
        </h6>
      </div>
      <div class="flex justify-between mb-8 mt-16">
        <span>{{ $t('total') }}</span>
        <span class="font-bold">{{ selectedCurrency }} {{ addonTotal | currency }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import RoomDetail from './roomDetail'
export default {
  name: 'modal-item-detail',
  components: {
    RoomDetail
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    plan: {
      type: Object,
      default: () => ({})
    },
    addon: {
      type: Object,
      default: () => ({})
    },
    addonTotal: {
      type: Number,
      default: 0
    },
    type: {
      type: String,
      default: 'room'
    }
  },
  methods: {
    handleClose () {
      this.$emit('close')
    },
    displayDate (item) {
      return this.$dayjs(item.date).format('YYYY-MM-DD')
    },
    displayTime (item) {
      const formated = this.$dayjs().format('YYYY/MM/DD ') + item.session
      let timeParse = this.$dayjs(formated).format('HH:mm')
      if (!item.session) {
        timeParse = this.$t('unlimitedTime')
      }
      return timeParse
    },
    isGoods: function (item) {
      return item.type === 'hotel-goods'
    },
    addonRowTotal (item) {
      const convertedPrice = this.$options.filters.currency(item.price_without_fee, { inputCurrency: item.currency, isBonus: true })
      return this.$numeral(this.$numeral(convertedPrice).value() * item.qty).format('0,0[.]00')
    }
  },
  computed: {
    ...mapGetters([
      'selectedCurrency',
      'order/extraReceptionPriceConfig',
      'search/nights'
    ]),
    extraReceptionPriceTotal () {
      if (this.plan.extraReception) {
        return (this.plan.extraReception.reduce((pv, cv) => pv + cv.adults * this['order/extraReceptionPriceConfig'].adults + cv.children * this['order/extraReceptionPriceConfig'].children, 0)) * this['search/nights']
      }
      return 0
    },
    roomTotalPrice () {
      return this.plan.avgPrice * this['search/nights']
    },
    totalPrice ({ extraReceptionPriceTotal, roomTotalPrice }) {
      return extraReceptionPriceTotal + roomTotalPrice
    },
    addonName () {
      return this.addon.items?.[0].name
    },
    hasExtraTaxFeeConfig () {
      return !!this.addon.items?.[0]?.feeConfig?.length
    }
  }
}
</script>

<style lang='sass' scoped>
  .item-detail
    &__header
      @apply flex justify-between items-center w-full py-16 px-24
    &__content
      @apply border-t-1 border-grey-lighter py-16 px-24
      max-height: 500px
      -webkit-overflow-scrolling: touch
      overflow-y: auto
</style>
