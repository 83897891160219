var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main",
    { staticClass: "payment" },
    [
      _c("processSteps", {
        staticStyle: {
          "max-width": "724px",
          "margin-left": "auto",
          "margin-right": "auto"
        },
        attrs: { fullWidth: "" }
      }),
      _c(
        "section",
        { staticClass: "container", staticStyle: { "max-width": "724px" } },
        [
          _c("div", { staticClass: "payment__body" }, [
            _c(
              "div",
              { staticClass: "payment__body__infos lg:pr-12" },
              [
                _c(
                  "blockCard",
                  {
                    staticClass: "orderer-info",
                    attrs: { title: _vm.$t("customerInfo") }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "order-info__row flex flex-wrap" },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "form-group w-full sm:w-1/2 sm:pr-24"
                          },
                          [
                            _c("label", { staticClass: "is-required" }, [
                              _vm._v(_vm._s(_vm.$t("lastName")))
                            ]),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.customer.lastName,
                                  expression: "customer.lastName"
                                }
                              ],
                              staticClass: "input",
                              class: {
                                "input-error": _vm.validation.firstError(
                                  "customer.lastName"
                                )
                              },
                              attrs: {
                                type: "text",
                                disabled: _vm.isSubmitting,
                                placeholder: _vm.$t("lastNamePlaceholder")
                              },
                              domProps: { value: _vm.customer.lastName },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.customer,
                                    "lastName",
                                    $event.target.value
                                  )
                                }
                              }
                            }),
                            _vm.validation.firstError("customer.lastName")
                              ? _c(
                                  "span",
                                  { staticClass: "format-error-notify" },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.lastNameValidatedErrorMsg)
                                    )
                                  ]
                                )
                              : _vm._e()
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "form-group w-full sm:w-1/2" },
                          [
                            _c("label", { staticClass: "is-required" }, [
                              _vm._v(_vm._s(_vm.$t("firstName")))
                            ]),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.customer.firstName,
                                  expression: "customer.firstName"
                                }
                              ],
                              staticClass: "input",
                              class: {
                                "input-error": _vm.validation.firstError(
                                  "customer.firstName"
                                )
                              },
                              attrs: {
                                type: "text",
                                disabled: _vm.isSubmitting,
                                placeholder: _vm.$t("firstNamePlaceholder")
                              },
                              domProps: { value: _vm.customer.firstName },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.customer,
                                    "firstName",
                                    $event.target.value
                                  )
                                }
                              }
                            }),
                            _vm.validation.firstError("customer.firstName")
                              ? _c(
                                  "span",
                                  { staticClass: "format-error-notify" },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.firstNameValidatedErrorMsg)
                                    )
                                  ]
                                )
                              : _vm._e()
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "form-group w-full sm:w-1/2 sm:pr-24"
                          },
                          [
                            _c("label", { staticClass: "is-required" }, [
                              _vm._v(_vm._s(_vm.$t("country")))
                            ]),
                            _c("multiSelect", {
                              attrs: {
                                "track-by": "name",
                                label: "name",
                                options: _vm.countries,
                                "show-labels": false,
                                "close-on-select": true,
                                "allow-empty": false,
                                disabled: _vm.isSubmitting
                              },
                              on: { select: _vm.selectCountry },
                              model: {
                                value: _vm.currentCountrySelect,
                                callback: function($$v) {
                                  _vm.currentCountrySelect = $$v
                                },
                                expression: "currentCountrySelect"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "form-group w-full sm:w-1/2" },
                          [
                            _c("label", { staticClass: "is-required" }, [
                              _vm._v(_vm._s(_vm.$t("phone")))
                            ]),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.customer.phone,
                                  expression: "customer.phone"
                                }
                              ],
                              staticClass: "input",
                              class: {
                                "input-error": _vm.validation.firstError(
                                  "customer.phone"
                                )
                              },
                              attrs: {
                                type: "tel",
                                disabled: _vm.isSubmitting,
                                placeholder: "+886988777654 or 0988777654",
                                inputmode: "tel"
                              },
                              domProps: { value: _vm.customer.phone },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.customer,
                                    "phone",
                                    $event.target.value
                                  )
                                }
                              }
                            }),
                            _vm.validation.firstError("customer.phone")
                              ? _c(
                                  "span",
                                  { staticClass: "format-error-notify" },
                                  [_vm._v(_vm._s(_vm.phoneValidatedErrorMsg))]
                                )
                              : _vm._e()
                          ]
                        ),
                        _c("div", { staticClass: "form-group w-full" }, [
                          _c("label", { staticClass: "is-required" }, [
                            _vm._v(_vm._s(_vm.$t("email")))
                          ]),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.customer.email,
                                expression: "customer.email"
                              }
                            ],
                            staticClass: "input",
                            class: {
                              "input-error": _vm.validation.firstError(
                                "customer.email"
                              )
                            },
                            attrs: {
                              type: "email",
                              disabled: _vm.isSubmitting,
                              inputmode: "email"
                            },
                            domProps: { value: _vm.customer.email },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.customer,
                                  "email",
                                  $event.target.value
                                )
                              }
                            }
                          }),
                          _vm.validation.firstError("customer.email")
                            ? _c(
                                "span",
                                { staticClass: "format-error-notify" },
                                [_vm._v(_vm._s(_vm.emailValidatedErrorMsg))]
                              )
                            : _vm._e()
                        ]),
                        _c("div", { staticClass: "form-group w-full" }, [
                          _c("label", [_vm._v(_vm._s(_vm.$t("specialNeeds")))]),
                          _c("textarea", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.customer.specific,
                                expression: "customer.specific"
                              }
                            ],
                            staticClass: "input w-full p-16 mb-24",
                            attrs: {
                              placeholder: _vm.$t("order.specific"),
                              disabled: _vm.isSubmitting,
                              cols: "30",
                              rows: "6"
                            },
                            domProps: { value: _vm.customer.specific },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.customer,
                                  "specific",
                                  $event.target.value
                                )
                              }
                            }
                          }),
                          _c(
                            "div",
                            {
                              staticClass:
                                "specific-request__note flex items-center"
                            },
                            [
                              _c("i", {
                                staticClass: "owl-status-triangle-warning mr-8"
                              }),
                              _c(
                                "p",
                                { staticClass: "text-grey-darkest text-sm" },
                                [_vm._v(_vm._s(_vm.$t("orderWarningMsg")))]
                              )
                            ]
                          )
                        ])
                      ]
                    )
                  ]
                ),
                _vm.isPrecautions
                  ? _c(
                      "blockCard",
                      {
                        staticClass: "precautions",
                        attrs: { title: _vm.$t("checkInInstructions") }
                      },
                      [
                        _c("p", {
                          staticClass:
                            "text-sm text-grey-darkest mb-4 whitespace-pre-wrap leading-normal",
                          domProps: { innerHTML: _vm._s(this.hotelHotel.terms) }
                        })
                      ]
                    )
                  : _vm._e(),
                _c(
                  "blockCard",
                  { staticClass: "order-detail", attrs: { noPadding: "" } },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "order-detail__head py-24 md:p-24 border-b-1 border-grey-lighter"
                      },
                      [
                        _c("h4", { staticClass: "font-medium mb-16" }, [
                          _vm._v(_vm._s(_vm.$t("orderDetail")))
                        ]),
                        _c(
                          "div",
                          {
                            staticClass:
                              "order-detail__head text-grey-darkest flex flex-col md:flex-row"
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "order-detail__head__row mb-16 md:mb-0 w-full md:w-1/2"
                              },
                              [
                                _c("i", {
                                  staticClass:
                                    "owl-calendar mr-8 text-grey text-xl"
                                }),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("dayFormat")(
                                        this.searchDateRange.start
                                      )
                                    )
                                  )
                                ]),
                                _c("span", [_vm._v(" - ")]),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("dayFormat")(
                                        this.searchDateRange.end
                                      )
                                    )
                                  )
                                ]),
                                _c(
                                  "small",
                                  { staticClass: "ml-8 text-grey-dark" },
                                  [
                                    _vm._v(
                                      "( " +
                                        _vm._s(_vm.diffDays + 1) +
                                        " " +
                                        _vm._s(
                                          _vm.$tc("day", _vm.diffDays + 1)
                                        ) +
                                        " " +
                                        _vm._s(_vm.diffDays) +
                                        " " +
                                        _vm._s(_vm.$tc("night", _vm.diffDays)) +
                                        " )"
                                    )
                                  ]
                                )
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "order-detail__head__row w-full md:w-1/2"
                              },
                              [
                                _c("i", {
                                  staticClass:
                                    "owl-user-group mr-8 text-grey text-xl"
                                }),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(this.searchPeople.adult) +
                                      " " +
                                      _vm._s(
                                        _vm.$tc(
                                          "adult",
                                          this.searchPeople.adult
                                        )
                                      )
                                  )
                                ]),
                                _c("span", [
                                  _vm._v(
                                    ", " +
                                      _vm._s(this.searchPeople.child) +
                                      " " +
                                      _vm._s(
                                        _vm.$tc(
                                          "child",
                                          this.searchPeople.child
                                        )
                                      )
                                  )
                                ]),
                                _c("span", [
                                  _vm._v(
                                    ", " +
                                      _vm._s(this.searchPeople.infant) +
                                      " " +
                                      _vm._s(
                                        _vm.$tc(
                                          "infant",
                                          this.searchPeople.infant
                                        )
                                      )
                                  )
                                ])
                              ]
                            )
                          ]
                        )
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "order-detail__items md:px-24" },
                      [
                        _vm._l(this.orderPlans, function(plan) {
                          return _c("hotelCardOrder", {
                            key: plan.id,
                            attrs: { plan: plan }
                          })
                        }),
                        _vm._l(this.orderAddons, function(addon) {
                          return _c("addonCardOrder", {
                            key: addon.id,
                            attrs: { addon: addon }
                          })
                        })
                      ],
                      2
                    )
                  ]
                ),
                _c(
                  "blockCard",
                  { staticClass: "order-price", attrs: { noPadding: "" } },
                  [
                    _c("div", { staticClass: "order-price__head p-24 pb-0" }, [
                      _c("h4", { staticClass: "font-medium mb-16" }, [
                        _vm._v(_vm._s(_vm.$t("order_payment_detail")))
                      ]),
                      _vm.isHotelCouponActive
                        ? _c("div", [
                            _c("div", { staticClass: "order-price__coupon" }, [
                              _c(
                                "div",
                                { staticClass: "order-price__coupon-code" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "flex flex-wrap md:flex-no-wrap flex-initial justify-between"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "order-price__coupon-code__input-group w-full md:mr-16"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "text-sm mb-16 ml-4"
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("officialCoupon"))
                                              )
                                            ]
                                          ),
                                          _c(
                                            "label",
                                            {
                                              staticClass:
                                                "input input-buttons coupon-input"
                                            },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: _vm.couponCode,
                                                    expression: "couponCode"
                                                  }
                                                ],
                                                attrs: {
                                                  type: "text",
                                                  placeholder: _vm.$t(
                                                    "couponPlaceHolder"
                                                  ),
                                                  disabled: _vm.isSubmitting
                                                },
                                                domProps: {
                                                  value: _vm.couponCode
                                                },
                                                on: {
                                                  keypress: function($event) {
                                                    if (
                                                      !$event.type.indexOf(
                                                        "key"
                                                      ) &&
                                                      _vm._k(
                                                        $event.keyCode,
                                                        "enter",
                                                        13,
                                                        $event.key,
                                                        "Enter"
                                                      )
                                                    ) {
                                                      return null
                                                    }
                                                    return _vm.onCouponSubmit.apply(
                                                      null,
                                                      arguments
                                                    )
                                                  },
                                                  input: function($event) {
                                                    if (
                                                      $event.target.composing
                                                    ) {
                                                      return
                                                    }
                                                    _vm.couponCode =
                                                      $event.target.value
                                                  }
                                                }
                                              }),
                                              _c(
                                                "button",
                                                {
                                                  staticClass:
                                                    "input-btn custom-blue-input-btn",
                                                  on: {
                                                    click: _vm.onCouponSubmit
                                                  }
                                                },
                                                [_vm._v(_vm._s(_vm.$t("use")))]
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.couponResultErrors
                                                      .length,
                                                  expression:
                                                    "couponResultErrors.length"
                                                }
                                              ],
                                              staticClass:
                                                "flex flex-col text-sm mt-8"
                                            },
                                            _vm._l(
                                              _vm.couponResultErrors,
                                              function(error) {
                                                return _c(
                                                  "span",
                                                  {
                                                    key: error,
                                                    staticClass:
                                                      "text-orange-dark leading-normal"
                                                  },
                                                  [_vm._v(_vm._s(error))]
                                                )
                                              }
                                            ),
                                            0
                                          )
                                        ]
                                      ),
                                      _vm.isCouponValid && _vm.isCouponTriggered
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "order-price__coupon-code__result w-full mt-8 lg:mt-0"
                                            },
                                            _vm._l(
                                              _vm.previewResult.coupons,
                                              function(coupon, index) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: index,
                                                    staticClass: "text-sm mb-8"
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "applied-coupon border-1 border-grey-lighter border-l-8"
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "owl-status-error applied-coupon__close",
                                                          on: {
                                                            click:
                                                              _vm.removeCoupon
                                                          }
                                                        }),
                                                        _c(
                                                          "h6",
                                                          {
                                                            staticClass:
                                                              "text-grey-darkest font-bold text-sm"
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                coupon.code
                                                              )
                                                            )
                                                          ]
                                                        ),
                                                        _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "text-green-darker pr-8 inline-block"
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.couponDiscountText(
                                                                  coupon
                                                                )
                                                              )
                                                            )
                                                          ]
                                                        ),
                                                        _c(
                                                          "small",
                                                          {
                                                            directives: [
                                                              {
                                                                name: "show",
                                                                rawName:
                                                                  "v-show",
                                                                value:
                                                                  coupon.limit_max_price >
                                                                  0,
                                                                expression:
                                                                  "coupon.limit_max_price > 0"
                                                              }
                                                            ],
                                                            staticClass:
                                                              "text-xs text-grey-dark"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "( " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "maximumDiscount"
                                                                  )
                                                                ) +
                                                                " " +
                                                                _vm._s(
                                                                  _vm.selectedCurrency
                                                                ) +
                                                                " " +
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "currency"
                                                                  )(
                                                                    coupon.limit_max_price,
                                                                    {
                                                                      outputCurrency:
                                                                        _vm.hotelHotelOriginCurrency
                                                                    }
                                                                  )
                                                                ) +
                                                                " )"
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          )
                                        : _vm._e(),
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                _vm.shouldShowCouponCodeConditions,
                                              expression:
                                                "shouldShowCouponCodeConditions"
                                            }
                                          ],
                                          staticClass:
                                            "order-price__coupon-code__all-content w-full"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "order-price__coupon-code__all-content__head"
                                            },
                                            [
                                              _c(
                                                "h6",
                                                {
                                                  staticClass:
                                                    "text-sm text-grey-darkest mb-8 font-bold"
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.appliedCouponContent
                                                        .code
                                                    )
                                                  )
                                                ]
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "order-price__coupon-code__all-content__body"
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "order-price__coupon-code__all-content__body__row"
                                                },
                                                [
                                                  _c("label", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "coupon_cost_threshold"
                                                        )
                                                      ) + "："
                                                    )
                                                  ]),
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.appliedCouponContentPriceCondition
                                                      )
                                                    )
                                                  ])
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "order-price__coupon-code__all-content__body__row"
                                                },
                                                [
                                                  _c("label", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "coupon_apply_check_in_date"
                                                        )
                                                      ) + "："
                                                    )
                                                  ]),
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.appliedCouponContentDate
                                                      ) +
                                                        " " +
                                                        _vm._s(
                                                          _vm.appliedCouponContentDateSubNote
                                                            ? "(" +
                                                                _vm.appliedCouponContentDateSubNote +
                                                                ")"
                                                            : null
                                                        )
                                                    )
                                                  ])
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "order-price__coupon-code__all-content__body__row"
                                                },
                                                [
                                                  _c("label", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "coupon_apply_rooms"
                                                        )
                                                      ) + "："
                                                    )
                                                  ]),
                                                  _c("span", {
                                                    domProps: {
                                                      innerHTML: _vm._s(
                                                        _vm.appliedCouponContentRooms
                                                      )
                                                    }
                                                  })
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "order-price__coupon-code__all-content__body__row"
                                                },
                                                [
                                                  _c("label", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "coupon_apply_plans"
                                                        )
                                                      ) + "："
                                                    )
                                                  ]),
                                                  _c("span", {
                                                    domProps: {
                                                      innerHTML: _vm._s(
                                                        _vm.appliedCouponContentPlans
                                                      )
                                                    }
                                                  })
                                                ]
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ]
                              )
                            ])
                          ])
                        : _vm._e()
                    ]),
                    _c(
                      "div",
                      { staticClass: "order-price__body p-24" },
                      [
                        !_vm.hasDeposit && !_vm.isCouponAvailable
                          ? [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "order-price__row mb-8 leading-normal"
                                },
                                [
                                  _c(
                                    "label",
                                    { staticClass: "text-grey-darkest" },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(_vm.$t("roomsCost")) +
                                          "\n                  "
                                      ),
                                      !_vm.hasPlanFeeConfig
                                        ? _c("small", [
                                            _vm._v(
                                              "(" +
                                                _vm._s(
                                                  _vm.$t(
                                                    "room_price_has_tax_included"
                                                  )
                                                ) +
                                                ")"
                                            )
                                          ])
                                        : _vm._e()
                                    ]
                                  ),
                                  _c("div", { staticClass: "text-right" }, [
                                    _c(
                                      "span",
                                      { staticClass: "block text-sm" },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.selectedCurrency) + " "
                                        ),
                                        _c("big", { staticClass: "text-sm" }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("currency")(
                                                _vm.resultCommon.plansTotalPrice
                                              )
                                            )
                                          )
                                        ])
                                      ],
                                      1
                                    ),
                                    _c(
                                      "span",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: !_vm.isCurrencyMatchHotelCurrency,
                                            expression:
                                              "!isCurrencyMatchHotelCurrency"
                                          }
                                        ],
                                        staticClass:
                                          "text-sm text-grey-dark block mt-4"
                                      },
                                      [
                                        _vm._v(
                                          "\n                    ( " +
                                            _vm._s(
                                              _vm.hotelHotelOriginCurrency
                                            ) +
                                            " " +
                                            _vm._s(
                                              _vm._f("currency")(
                                                _vm.resultCommon
                                                  .plansTotalPrice,
                                                {
                                                  outputCurrency:
                                                    _vm.hotelHotelOriginCurrency
                                                }
                                              )
                                            ) +
                                            " )\n                  "
                                        )
                                      ]
                                    )
                                  ])
                                ]
                              ),
                              _vm.hasPlanFeeConfig
                                ? _vm._l(_vm.planFeeConfigs, function(fee) {
                                    return _c(
                                      "div",
                                      {
                                        key: "fee-" + fee.id + "-" + fee.name,
                                        staticClass:
                                          "order-price__row mb-8 leading-normal indent"
                                      },
                                      [
                                        _c(
                                          "label",
                                          {
                                            staticClass:
                                              "text-sm text-grey-darkest"
                                          },
                                          [_vm._v(_vm._s(fee.name))]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "text-right" },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "block text-sm text-grey-darkest"
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.selectedCurrency) +
                                                    " "
                                                ),
                                                _c(
                                                  "big",
                                                  { staticClass: "text-sm" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm._f("currency")(
                                                          fee.price
                                                        )
                                                      )
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "span",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value: !_vm.isCurrencyMatchHotelCurrency,
                                                    expression:
                                                      "!isCurrencyMatchHotelCurrency"
                                                  }
                                                ],
                                                staticClass:
                                                  "text-sm text-grey-dark block mt-4"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                      ( " +
                                                    _vm._s(
                                                      _vm.hotelHotelOriginCurrency
                                                    ) +
                                                    " " +
                                                    _vm._s(
                                                      _vm._f("currency")(
                                                        fee.price,
                                                        {
                                                          outputCurrency:
                                                            _vm.hotelHotelOriginCurrency
                                                        }
                                                      )
                                                    ) +
                                                    " )\n                    "
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  })
                                : _vm._e(),
                              _c("extraReceptionPrice"),
                              _vm.hasAddons
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "order-price__row mb-8 leading-normal"
                                    },
                                    [
                                      _c(
                                        "label",
                                        {
                                          staticClass:
                                            "text-sm text-grey-darkest"
                                        },
                                        [_vm._v(_vm._s(_vm.$t("addonsCost")))]
                                      ),
                                      _c("div", { staticClass: "text-right" }, [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "block text-sm text-grey-darkest"
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.selectedCurrency) + " "
                                            ),
                                            _c(
                                              "big",
                                              { staticClass: "text-sm" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm._f("currency")(
                                                      _vm.resultCommon
                                                        .addonsTotalPrice
                                                    )
                                                  )
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "span",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: !_vm.isCurrencyMatchHotelCurrency,
                                                expression:
                                                  "!isCurrencyMatchHotelCurrency"
                                              }
                                            ],
                                            staticClass:
                                              "text-sm text-grey-dark block mt-4"
                                          },
                                          [
                                            _vm._v(
                                              "\n                    ( " +
                                                _vm._s(
                                                  _vm.hotelHotelOriginCurrency
                                                ) +
                                                " " +
                                                _vm._s(
                                                  _vm._f("currency")(
                                                    _vm.resultCommon
                                                      .addonsTotalPrice,
                                                    {
                                                      outputCurrency:
                                                        _vm.hotelHotelOriginCurrency
                                                    }
                                                  )
                                                ) +
                                                " )\n                  "
                                            )
                                          ]
                                        )
                                      ])
                                    ]
                                  )
                                : _vm._e(),
                              _vm.hasAddonFeeConfig
                                ? _vm._l(_vm.addonFeeConfigs, function(fee) {
                                    return _c(
                                      "div",
                                      {
                                        key: "fee-" + fee.id + "-" + fee.name,
                                        staticClass:
                                          "order-price__row mb-8 leading-normal indent"
                                      },
                                      [
                                        _c(
                                          "label",
                                          {
                                            staticClass:
                                              "text-sm text-grey-darkest"
                                          },
                                          [_vm._v(_vm._s(fee.name))]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "text-right" },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "block text-sm text-grey-darkest"
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.selectedCurrency) +
                                                    " "
                                                ),
                                                _c(
                                                  "big",
                                                  { staticClass: "text-sm" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm._f("currency")(
                                                          fee.price
                                                        )
                                                      )
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "span",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value: !_vm.isCurrencyMatchHotelCurrency,
                                                    expression:
                                                      "!isCurrencyMatchHotelCurrency"
                                                  }
                                                ],
                                                staticClass:
                                                  "text-sm text-grey-dark block mt-4"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                      ( " +
                                                    _vm._s(
                                                      _vm.hotelHotelOriginCurrency
                                                    ) +
                                                    " " +
                                                    _vm._s(
                                                      _vm._f("currency")(
                                                        fee.price,
                                                        {
                                                          outputCurrency:
                                                            _vm.hotelHotelOriginCurrency
                                                        }
                                                      )
                                                    ) +
                                                    " )\n                    "
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  })
                                : _vm._e(),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "order-price__row text-grey-darker leading-normal font-bold"
                                },
                                [
                                  _c(
                                    "label",
                                    { staticClass: "text-grey-darkest" },
                                    [_vm._v(_vm._s(_vm.$t("total")))]
                                  ),
                                  _c("div", { staticClass: "text-right" }, [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "block text-sm text-grey-darkest"
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.selectedCurrency) + " "
                                        ),
                                        _c(
                                          "big",
                                          { staticClass: "text-xl text-blue" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("currency")(
                                                  _vm.resultCommon.totalPrice
                                                )
                                              )
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "span",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: !_vm.isCurrencyMatchHotelCurrency,
                                            expression:
                                              "!isCurrencyMatchHotelCurrency"
                                          }
                                        ],
                                        staticClass:
                                          "text-sm text-grey-dark block mt-4"
                                      },
                                      [
                                        _vm._v(
                                          "\n                    ( " +
                                            _vm._s(
                                              _vm.hotelHotelOriginCurrency
                                            ) +
                                            " " +
                                            _vm._s(
                                              _vm._f("currency")(
                                                _vm.resultCommon.totalPrice,
                                                {
                                                  outputCurrency:
                                                    _vm.hotelHotelOriginCurrency
                                                }
                                              )
                                            ) +
                                            " )\n                  "
                                        )
                                      ]
                                    )
                                  ])
                                ]
                              )
                            ]
                          : _vm._e(),
                        _vm.hasDeposit && !_vm.isCouponAvailable
                          ? [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "order-price__row mb-8 text-grey-darkest leading-normal"
                                },
                                [
                                  _c("label", { staticClass: "text-sm" }, [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(_vm.$t("roomsCost")) +
                                        "\n                  "
                                    ),
                                    !_vm.hasPlanFeeConfig
                                      ? _c("small", [
                                          _vm._v(
                                            "(" +
                                              _vm._s(
                                                _vm.$t(
                                                  "room_price_has_tax_included"
                                                )
                                              ) +
                                              ")"
                                          )
                                        ])
                                      : _vm._e()
                                  ]),
                                  _c("div", { staticClass: "text-right" }, [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "block text-sm text-grey-darkest"
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.selectedCurrency) + " "
                                        ),
                                        _c("big", { staticClass: "text-sm" }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("currency")(
                                                _vm.resultDeposit
                                                  .plansTotalPrice
                                              )
                                            )
                                          )
                                        ])
                                      ],
                                      1
                                    ),
                                    _c(
                                      "span",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: !_vm.isCurrencyMatchHotelCurrency,
                                            expression:
                                              "!isCurrencyMatchHotelCurrency"
                                          }
                                        ],
                                        staticClass:
                                          "text-sm text-grey-dark block mt-4"
                                      },
                                      [
                                        _vm._v(
                                          "\n                    ( " +
                                            _vm._s(
                                              _vm.hotelHotelOriginCurrency
                                            ) +
                                            " " +
                                            _vm._s(
                                              _vm._f("currency")(
                                                _vm.resultDeposit
                                                  .plansTotalPrice,
                                                {
                                                  outputCurrency:
                                                    _vm.hotelHotelOriginCurrency
                                                }
                                              )
                                            ) +
                                            " )\n                  "
                                        )
                                      ]
                                    )
                                  ])
                                ]
                              ),
                              _vm.hasPlanFeeConfig
                                ? _vm._l(_vm.planFeeConfigs, function(fee) {
                                    return _c(
                                      "div",
                                      {
                                        key: "fee-" + fee.id + "-" + fee.name,
                                        staticClass:
                                          "order-price__row mb-8 leading-normal indent"
                                      },
                                      [
                                        _c(
                                          "label",
                                          {
                                            staticClass:
                                              "text-sm text-grey-darkest"
                                          },
                                          [_vm._v(_vm._s(fee.name))]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "text-right" },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "block text-sm text-grey-darkest"
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.selectedCurrency) +
                                                    " "
                                                ),
                                                _c(
                                                  "big",
                                                  { staticClass: "text-sm" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm._f("currency")(
                                                          fee.price
                                                        )
                                                      )
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "span",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value: !_vm.isCurrencyMatchHotelCurrency,
                                                    expression:
                                                      "!isCurrencyMatchHotelCurrency"
                                                  }
                                                ],
                                                staticClass:
                                                  "text-sm text-grey-dark block mt-4"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                      ( " +
                                                    _vm._s(
                                                      _vm.hotelHotelOriginCurrency
                                                    ) +
                                                    " " +
                                                    _vm._s(
                                                      _vm._f("currency")(
                                                        fee.price,
                                                        {
                                                          outputCurrency:
                                                            _vm.hotelHotelOriginCurrency
                                                        }
                                                      )
                                                    ) +
                                                    " )\n                    "
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  })
                                : _vm._e(),
                              _c("extraReceptionPrice"),
                              _vm.hasAddons
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "order-price__row mb-8 leading-normal"
                                    },
                                    [
                                      _c(
                                        "label",
                                        {
                                          staticClass:
                                            "text-sm text-grey-darkest"
                                        },
                                        [_vm._v(_vm._s(_vm.$t("addonsCost")))]
                                      ),
                                      _c("div", { staticClass: "text-right" }, [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "block text-sm text-grey-darkest"
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.selectedCurrency) + " "
                                            ),
                                            _c(
                                              "big",
                                              { staticClass: "text-sm" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm._f("currency")(
                                                      _vm.resultDeposit
                                                        .addonsTotalPrice
                                                    )
                                                  )
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "span",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: !_vm.isCurrencyMatchHotelCurrency,
                                                expression:
                                                  "!isCurrencyMatchHotelCurrency"
                                              }
                                            ],
                                            staticClass:
                                              "text-sm text-grey-dark block mt-4"
                                          },
                                          [
                                            _vm._v(
                                              "\n                    ( " +
                                                _vm._s(
                                                  _vm.hotelHotelOriginCurrency
                                                ) +
                                                " " +
                                                _vm._s(
                                                  _vm._f("currency")(
                                                    _vm.resultDeposit
                                                      .addonsTotalPrice,
                                                    {
                                                      outputCurrency:
                                                        _vm.hotelHotelOriginCurrency
                                                    }
                                                  )
                                                ) +
                                                " )\n                  "
                                            )
                                          ]
                                        )
                                      ])
                                    ]
                                  )
                                : _vm._e(),
                              _vm.hasAddonFeeConfig
                                ? _vm._l(_vm.addonFeeConfigs, function(fee) {
                                    return _c(
                                      "div",
                                      {
                                        key: "fee-" + fee.id + "-" + fee.name,
                                        staticClass:
                                          "order-price__row mb-8 leading-normal indent"
                                      },
                                      [
                                        _c(
                                          "label",
                                          {
                                            staticClass:
                                              "text-sm text-grey-darkest"
                                          },
                                          [_vm._v(_vm._s(fee.name))]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "text-right" },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "block text-sm text-grey-darkest"
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.selectedCurrency) +
                                                    " "
                                                ),
                                                _c(
                                                  "big",
                                                  { staticClass: "text-sm" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm._f("currency")(
                                                          fee.price
                                                        )
                                                      )
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "span",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value: !_vm.isCurrencyMatchHotelCurrency,
                                                    expression:
                                                      "!isCurrencyMatchHotelCurrency"
                                                  }
                                                ],
                                                staticClass:
                                                  "text-sm text-grey-dark block mt-4"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                      ( " +
                                                    _vm._s(
                                                      _vm.hotelHotelOriginCurrency
                                                    ) +
                                                    " " +
                                                    _vm._s(
                                                      _vm._f("currency")(
                                                        fee.price,
                                                        {
                                                          outputCurrency:
                                                            _vm.hotelHotelOriginCurrency
                                                        }
                                                      )
                                                    ) +
                                                    " )\n                    "
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  })
                                : _vm._e(),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "order-price__row mb-8 leading-normal font-bold"
                                },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "text-sm text-grey-darkest"
                                    },
                                    [_vm._v(_vm._s(_vm.$t("total")))]
                                  ),
                                  _c("div", { staticClass: "text-right" }, [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "block text-sm text-grey-darkest"
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.selectedCurrency) +
                                            " " +
                                            _vm._s(
                                              _vm._f("currency")(
                                                _vm.resultDeposit.totalPrice
                                              )
                                            )
                                        )
                                      ]
                                    ),
                                    _c(
                                      "span",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: !_vm.isCurrencyMatchHotelCurrency,
                                            expression:
                                              "!isCurrencyMatchHotelCurrency"
                                          }
                                        ],
                                        staticClass:
                                          "text-sm text-grey-dark block mt-4 mb-16"
                                      },
                                      [
                                        _vm._v(
                                          "\n                    ( " +
                                            _vm._s(
                                              _vm.hotelHotelOriginCurrency
                                            ) +
                                            " " +
                                            _vm._s(
                                              _vm._f("currency")(
                                                _vm.resultDeposit.totalPrice,
                                                {
                                                  outputCurrency:
                                                    _vm.hotelHotelOriginCurrency
                                                }
                                              )
                                            ) +
                                            " )\n                  "
                                        )
                                      ]
                                    )
                                  ])
                                ]
                              ),
                              _c("div", { staticClass: "hr" }),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "order-price__row mb-8 leading-normal"
                                },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "text-sm text-grey-darkest"
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("depositTotalWithValue", [
                                            _vm.hotelHotelDepositRate
                                          ])
                                        )
                                      )
                                    ]
                                  ),
                                  _c("div", { staticClass: "text-right" }, [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "block text-sm text-grey-darkest"
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.selectedCurrency) + " "
                                        ),
                                        _c(
                                          "big",
                                          {
                                            staticClass:
                                              "text-xl text-blue font-medium"
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("currency")(
                                                  _vm.resultDeposit.depositPrice
                                                )
                                              )
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "span",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: !_vm.isCurrencyMatchHotelCurrency,
                                            expression:
                                              "!isCurrencyMatchHotelCurrency"
                                          }
                                        ],
                                        staticClass:
                                          "text-sm text-grey-dark block mt-4 mb-16"
                                      },
                                      [
                                        _vm._v(
                                          "\n                    ( " +
                                            _vm._s(
                                              _vm.hotelHotelOriginCurrency
                                            ) +
                                            " " +
                                            _vm._s(
                                              _vm._f("currency")(
                                                _vm.resultDeposit.depositPrice,
                                                {
                                                  outputCurrency:
                                                    _vm.hotelHotelOriginCurrency
                                                }
                                              )
                                            ) +
                                            " )\n                  "
                                        )
                                      ]
                                    )
                                  ])
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "order-price__row text-sm text-grey-darker leading-normal"
                                },
                                [
                                  _c("label", [
                                    _vm._v(_vm._s(_vm.$t("remainTotal")))
                                  ]),
                                  _c("div", { staticClass: "text-right" }, [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "block text-sm text-grey-darkest"
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.selectedCurrency) + " "
                                        ),
                                        _c("big", { staticClass: "text-sm" }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("currency")(
                                                _vm.resultDeposit.remainPrice
                                              )
                                            )
                                          )
                                        ])
                                      ],
                                      1
                                    ),
                                    _c(
                                      "span",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: !_vm.isCurrencyMatchHotelCurrency,
                                            expression:
                                              "!isCurrencyMatchHotelCurrency"
                                          }
                                        ],
                                        staticClass:
                                          "text-sm text-grey-dark block mt-4"
                                      },
                                      [
                                        _vm._v(
                                          "\n                    ( " +
                                            _vm._s(
                                              _vm.hotelHotelOriginCurrency
                                            ) +
                                            " " +
                                            _vm._s(
                                              _vm._f("currency")(
                                                _vm.resultDeposit.remainPrice,
                                                {
                                                  outputCurrency:
                                                    _vm.hotelHotelOriginCurrency
                                                }
                                              )
                                            ) +
                                            " )\n                  "
                                        )
                                      ]
                                    )
                                  ])
                                ]
                              )
                            ]
                          : _vm._e(),
                        !_vm.hasDeposit && _vm.isCouponAvailable
                          ? [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "order-price__row mb-8 leading-normal"
                                },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "text-sm text-grey-darkest"
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(_vm.$t("roomsCost")) +
                                          "\n                  "
                                      ),
                                      !_vm.hasPlanFeeConfig
                                        ? _c("small", [
                                            _vm._v(
                                              "(" +
                                                _vm._s(
                                                  _vm.$t(
                                                    "room_price_has_tax_included"
                                                  )
                                                ) +
                                                ")"
                                            )
                                          ])
                                        : _vm._e()
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "text-sm text-grey-darker text-right"
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "line-through" },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.selectedCurrency) +
                                              " " +
                                              _vm._s(
                                                _vm._f("currency")(
                                                  _vm.resultCoupon
                                                    .plansTotalPrice
                                                )
                                              )
                                          )
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: !_vm.isCurrencyMatchHotelCurrency,
                                              expression:
                                                "!isCurrencyMatchHotelCurrency"
                                            }
                                          ],
                                          staticClass:
                                            "text-sm text-grey-dark block mt-4"
                                        },
                                        [
                                          _vm._v(
                                            "( " +
                                              _vm._s(
                                                _vm.hotelHotelOriginCurrency
                                              ) +
                                              " " +
                                              _vm._s(
                                                _vm._f("currency")(
                                                  _vm.resultCoupon
                                                    .plansTotalPrice,
                                                  {
                                                    outputCurrency:
                                                      _vm.hotelHotelOriginCurrency
                                                  }
                                                )
                                              ) +
                                              " )"
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "order-price__row mb-8" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "text-sm text-grey-darkest leading-normal"
                                    },
                                    [_vm._v(_vm._s(_vm.$t("coupon")))]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "text-sm text-grey-darker flex"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "text-xs text-green-darker border border-green-dark rounded-full mr-8 py-4 px-8 mr-16 self-start"
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t("roomTotalDiscount"))
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "text-sm text-right" },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "text-grey-darker leading-normal"
                                            },
                                            [
                                              _vm._v(
                                                "-" +
                                                  _vm._s(_vm.selectedCurrency) +
                                                  " " +
                                                  _vm._s(
                                                    _vm._f("currency")(
                                                      _vm.resultCoupon
                                                        .discountPrice
                                                    )
                                                  )
                                              )
                                            ]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: !_vm.isCurrencyMatchHotelCurrency,
                                                  expression:
                                                    "!isCurrencyMatchHotelCurrency"
                                                }
                                              ],
                                              staticClass:
                                                "text-grey-dark block mt-4 leading-normal"
                                            },
                                            [
                                              _vm._v(
                                                "\n                      ( " +
                                                  _vm._s(
                                                    _vm.hotelHotelOriginCurrency
                                                  ) +
                                                  " " +
                                                  _vm._s(
                                                    _vm._f("currency")(
                                                      _vm.resultCoupon
                                                        .discountPrice,
                                                      {
                                                        outputCurrency:
                                                          _vm.hotelHotelOriginCurrency
                                                      }
                                                    )
                                                  ) +
                                                  " )\n                    "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ]
                              ),
                              _vm.hasPlanFeeConfig
                                ? _vm._l(_vm.planFeeConfigs, function(fee) {
                                    return _c(
                                      "div",
                                      {
                                        key: "fee-" + fee.id + "-" + fee.name,
                                        staticClass:
                                          "order-price__row mb-8 leading-normal indent"
                                      },
                                      [
                                        _c(
                                          "label",
                                          {
                                            staticClass:
                                              "text-sm text-grey-darkest"
                                          },
                                          [_vm._v(_vm._s(fee.name))]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "text-right" },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "block text-sm text-grey-darkest"
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.selectedCurrency) +
                                                    " "
                                                ),
                                                _c(
                                                  "big",
                                                  { staticClass: "text-sm" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm._f("currency")(
                                                          fee.price
                                                        )
                                                      )
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "span",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value: !_vm.isCurrencyMatchHotelCurrency,
                                                    expression:
                                                      "!isCurrencyMatchHotelCurrency"
                                                  }
                                                ],
                                                staticClass:
                                                  "text-sm text-grey-dark block mt-4"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                      ( " +
                                                    _vm._s(
                                                      _vm.hotelHotelOriginCurrency
                                                    ) +
                                                    " " +
                                                    _vm._s(
                                                      _vm._f("currency")(
                                                        fee.price,
                                                        {
                                                          outputCurrency:
                                                            _vm.hotelHotelOriginCurrency
                                                        }
                                                      )
                                                    ) +
                                                    " )\n                    "
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  })
                                : _vm._e(),
                              _c("extraReceptionPrice"),
                              _vm.hasAddons
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "order-price__row mb-8 leading-normal"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "text-sm text-grey-darkest"
                                        },
                                        [_vm._v(_vm._s(_vm.$t("addonsCost")))]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "text-sm text-grey-darker text-right"
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "text-grey-darkest"
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.selectedCurrency) +
                                                  " " +
                                                  _vm._s(
                                                    _vm._f("currency")(
                                                      _vm.resultCoupon
                                                        .addonsTotalPrice
                                                    )
                                                  )
                                              )
                                            ]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: !_vm.isCurrencyMatchHotelCurrency,
                                                  expression:
                                                    "!isCurrencyMatchHotelCurrency"
                                                }
                                              ],
                                              staticClass:
                                                "text-sm text-grey-dark block mt-4"
                                            },
                                            [
                                              _vm._v(
                                                "( " +
                                                  _vm._s(
                                                    _vm.hotelHotelOriginCurrency
                                                  ) +
                                                  " " +
                                                  _vm._s(
                                                    _vm._f("currency")(
                                                      _vm.resultCoupon
                                                        .addonsTotalPrice,
                                                      {
                                                        outputCurrency:
                                                          _vm.hotelHotelOriginCurrency
                                                      }
                                                    )
                                                  ) +
                                                  " )"
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm.hasAddonFeeConfig
                                ? _vm._l(_vm.addonFeeConfigs, function(fee) {
                                    return _c(
                                      "div",
                                      {
                                        key: "fee-" + fee.id + "-" + fee.name,
                                        staticClass:
                                          "order-price__row mb-8 leading-normal indent"
                                      },
                                      [
                                        _c(
                                          "label",
                                          {
                                            staticClass:
                                              "text-sm text-grey-darkest"
                                          },
                                          [_vm._v(_vm._s(fee.name))]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "text-right" },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "block text-sm text-grey-darkest"
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.selectedCurrency) +
                                                    " "
                                                ),
                                                _c(
                                                  "big",
                                                  { staticClass: "text-sm" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm._f("currency")(
                                                          fee.price
                                                        )
                                                      )
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "span",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value: !_vm.isCurrencyMatchHotelCurrency,
                                                    expression:
                                                      "!isCurrencyMatchHotelCurrency"
                                                  }
                                                ],
                                                staticClass:
                                                  "text-sm text-grey-dark block mt-4"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                      ( " +
                                                    _vm._s(
                                                      _vm.hotelHotelOriginCurrency
                                                    ) +
                                                    " " +
                                                    _vm._s(
                                                      _vm._f("currency")(
                                                        fee.price,
                                                        {
                                                          outputCurrency:
                                                            _vm.hotelHotelOriginCurrency
                                                        }
                                                      )
                                                    ) +
                                                    " )\n                    "
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  })
                                : _vm._e(),
                              _c("div", { staticClass: "hr" }),
                              _c(
                                "div",
                                { staticClass: "order-price__row font-bold" },
                                [
                                  _c(
                                    "label",
                                    { staticClass: "text-grey-darkest" },
                                    [_vm._v(_vm._s(_vm.$t("total")))]
                                  ),
                                  _c("div", { staticClass: "text-right" }, [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "block text-sm text-grey-darkest"
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.selectedCurrency) + " "
                                        ),
                                        _c(
                                          "big",
                                          {
                                            staticClass:
                                              "text-xl text-blue font-medium"
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("currency")(
                                                  _vm.resultCoupon
                                                    .discountFinalPrice
                                                )
                                              )
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "span",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: !_vm.isCurrencyMatchHotelCurrency,
                                            expression:
                                              "!isCurrencyMatchHotelCurrency"
                                          }
                                        ],
                                        staticClass:
                                          "text-sm text-grey-dark block mt-4"
                                      },
                                      [
                                        _vm._v(
                                          "\n                    ( " +
                                            _vm._s(
                                              _vm.hotelHotelOriginCurrency
                                            ) +
                                            " " +
                                            _vm._s(
                                              _vm._f("currency")(
                                                _vm.resultCoupon
                                                  .discountFinalPrice,
                                                {
                                                  outputCurrency:
                                                    _vm.hotelHotelOriginCurrency
                                                }
                                              )
                                            ) +
                                            " )\n                  "
                                        )
                                      ]
                                    )
                                  ])
                                ]
                              )
                            ]
                          : _vm._e(),
                        _vm.hasDeposit && _vm.isCouponAvailable
                          ? [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "order-price__row mb-8 leading-normal"
                                },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "text-sm text-grey-darkest"
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(_vm.$t("roomsCost")) +
                                          "\n                  "
                                      ),
                                      !_vm.hasPlanFeeConfig
                                        ? _c("small", [
                                            _vm._v(
                                              "(" +
                                                _vm._s(
                                                  _vm.$t(
                                                    "room_price_has_tax_included"
                                                  )
                                                ) +
                                                ")"
                                            )
                                          ])
                                        : _vm._e()
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "text-sm text-grey-darker text-right"
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "line-through" },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.selectedCurrency) +
                                              " " +
                                              _vm._s(
                                                _vm._f("currency")(
                                                  _vm.resultDepositCoupon
                                                    .plansTotalPrice
                                                )
                                              )
                                          )
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: !_vm.isCurrencyMatchHotelCurrency,
                                              expression:
                                                "!isCurrencyMatchHotelCurrency"
                                            }
                                          ],
                                          staticClass:
                                            "text-sm text-grey-dark block mt-4"
                                        },
                                        [
                                          _vm._v(
                                            "( " +
                                              _vm._s(
                                                _vm.hotelHotelOriginCurrency
                                              ) +
                                              " " +
                                              _vm._s(
                                                _vm._f("currency")(
                                                  _vm.resultDepositCoupon
                                                    .plansTotalPrice,
                                                  {
                                                    outputCurrency:
                                                      _vm.hotelHotelOriginCurrency
                                                  }
                                                )
                                              ) +
                                              " )"
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "order-price__row mb-8" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "text-sm text-grey-darkest leading-normal"
                                    },
                                    [_vm._v(_vm._s(_vm.$t("coupon")))]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "text-sm text-grey-darker flex"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "text-xs text-green-darker border border-green-dark rounded-full mr-8 py-4 px-8 mr-16 self-start"
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t("roomTotalDiscount"))
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "text-sm text-right" },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "text-grey-darker leading-normal"
                                            },
                                            [
                                              _vm._v(
                                                "-" +
                                                  _vm._s(_vm.selectedCurrency) +
                                                  " " +
                                                  _vm._s(
                                                    _vm._f("currency")(
                                                      _vm.resultDepositCoupon
                                                        .discountPrice
                                                    )
                                                  )
                                              )
                                            ]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: !_vm.isCurrencyMatchHotelCurrency,
                                                  expression:
                                                    "!isCurrencyMatchHotelCurrency"
                                                }
                                              ],
                                              staticClass:
                                                "text-grey-dark block mt-4 leading-normal"
                                            },
                                            [
                                              _vm._v(
                                                "\n                      ( " +
                                                  _vm._s(
                                                    _vm.hotelHotelOriginCurrency
                                                  ) +
                                                  " " +
                                                  _vm._s(
                                                    _vm._f("currency")(
                                                      _vm.resultDepositCoupon
                                                        .discountPrice,
                                                      {
                                                        outputCurrency:
                                                          _vm.hotelHotelOriginCurrency
                                                      }
                                                    )
                                                  ) +
                                                  " )\n                    "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ]
                              ),
                              _vm.hasPlanFeeConfig
                                ? _vm._l(_vm.planFeeConfigs, function(fee) {
                                    return _c(
                                      "div",
                                      {
                                        key: "fee-" + fee.id + "-" + fee.name,
                                        staticClass:
                                          "order-price__row mb-8 leading-normal indent"
                                      },
                                      [
                                        _c(
                                          "label",
                                          {
                                            staticClass:
                                              "text-sm text-grey-darkest"
                                          },
                                          [_vm._v(_vm._s(fee.name))]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "text-right" },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "block text-sm text-grey-darkest"
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.selectedCurrency) +
                                                    " "
                                                ),
                                                _c(
                                                  "big",
                                                  { staticClass: "text-sm" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm._f("currency")(
                                                          fee.price
                                                        )
                                                      )
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "span",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value: !_vm.isCurrencyMatchHotelCurrency,
                                                    expression:
                                                      "!isCurrencyMatchHotelCurrency"
                                                  }
                                                ],
                                                staticClass:
                                                  "text-sm text-grey-dark block mt-4"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                      ( " +
                                                    _vm._s(
                                                      _vm.hotelHotelOriginCurrency
                                                    ) +
                                                    " " +
                                                    _vm._s(
                                                      _vm._f("currency")(
                                                        fee.price,
                                                        {
                                                          outputCurrency:
                                                            _vm.hotelHotelOriginCurrency
                                                        }
                                                      )
                                                    ) +
                                                    " )\n                    "
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  })
                                : _vm._e(),
                              _c("extraReceptionPrice"),
                              _vm.hasAddons
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "order-price__row mb-8 leading-normal"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "text-sm text-grey-darkest"
                                        },
                                        [_vm._v(_vm._s(_vm.$t("addonsCost")))]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "text-sm text-right" },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "text-grey-darkest"
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.selectedCurrency) +
                                                  " " +
                                                  _vm._s(
                                                    _vm._f("currency")(
                                                      _vm.resultDepositCoupon
                                                        .addonsTotalPrice
                                                    )
                                                  )
                                              )
                                            ]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: !_vm.isCurrencyMatchHotelCurrency,
                                                  expression:
                                                    "!isCurrencyMatchHotelCurrency"
                                                }
                                              ],
                                              staticClass:
                                                "text-sm text-grey-dark block mt-4"
                                            },
                                            [
                                              _vm._v(
                                                "( " +
                                                  _vm._s(
                                                    _vm.hotelHotelOriginCurrency
                                                  ) +
                                                  " " +
                                                  _vm._s(
                                                    _vm._f("currency")(
                                                      _vm.resultDepositCoupon
                                                        .addonsTotalPrice,
                                                      {
                                                        outputCurrency:
                                                          _vm.hotelHotelOriginCurrency
                                                      }
                                                    )
                                                  ) +
                                                  " )"
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm.hasAddonFeeConfig
                                ? _vm._l(_vm.addonFeeConfigs, function(fee) {
                                    return _c(
                                      "div",
                                      {
                                        key: "fee-" + fee.id + "-" + fee.name,
                                        staticClass:
                                          "order-price__row mb-8 leading-normal indent"
                                      },
                                      [
                                        _c(
                                          "label",
                                          {
                                            staticClass:
                                              "text-sm text-grey-darkest"
                                          },
                                          [_vm._v(_vm._s(fee.name))]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "text-right" },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "block text-sm text-grey-darkest"
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.selectedCurrency) +
                                                    " "
                                                ),
                                                _c(
                                                  "big",
                                                  { staticClass: "text-sm" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm._f("currency")(
                                                          fee.price
                                                        )
                                                      )
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "span",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value: !_vm.isCurrencyMatchHotelCurrency,
                                                    expression:
                                                      "!isCurrencyMatchHotelCurrency"
                                                  }
                                                ],
                                                staticClass:
                                                  "text-sm text-grey-dark block mt-4"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                      ( " +
                                                    _vm._s(
                                                      _vm.hotelHotelOriginCurrency
                                                    ) +
                                                    " " +
                                                    _vm._s(
                                                      _vm._f("currency")(
                                                        fee.price,
                                                        {
                                                          outputCurrency:
                                                            _vm.hotelHotelOriginCurrency
                                                        }
                                                      )
                                                    ) +
                                                    " )\n                    "
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  })
                                : _vm._e(),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "order-price__row mb-8 leading-normal font-bold"
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "text-sm text-grey-darkest"
                                    },
                                    [_vm._v(_vm._s(_vm.$t("total")))]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "text-sm text-right" },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "text-grey-darkest" },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.selectedCurrency) +
                                              " " +
                                              _vm._s(
                                                _vm._f("currency")(
                                                  _vm.resultDepositCoupon
                                                    .discountFinalPrice
                                                )
                                              )
                                          )
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: !_vm.isCurrencyMatchHotelCurrency,
                                              expression:
                                                "!isCurrencyMatchHotelCurrency"
                                            }
                                          ],
                                          staticClass:
                                            "text-sm text-grey-dark block mt-4"
                                        },
                                        [
                                          _vm._v(
                                            "\n                    ( " +
                                              _vm._s(
                                                _vm.hotelHotelOriginCurrency
                                              ) +
                                              " " +
                                              _vm._s(
                                                _vm._f("currency")(
                                                  _vm.resultDepositCoupon
                                                    .discountFinalPrice,
                                                  {
                                                    outputCurrency:
                                                      _vm.hotelHotelOriginCurrency
                                                  }
                                                )
                                              ) +
                                              " )\n                  "
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ]
                              ),
                              _c("div", { staticClass: "hr" }),
                              _c(
                                "div",
                                { staticClass: "order-price__row mb-8" },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "text-sm text-grey-darkest"
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("depositTotalWithValue", [
                                            _vm.hotelHotelDepositRate
                                          ])
                                        )
                                      )
                                    ]
                                  ),
                                  _c("div", { staticClass: "text-right" }, [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "block text-sm text-grey-darkest"
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.selectedCurrency) + " "
                                        ),
                                        _c(
                                          "big",
                                          {
                                            staticClass:
                                              "text-xl text-blue font-medium"
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("currency")(
                                                  _vm.resultDepositCoupon
                                                    .depositPrice
                                                )
                                              )
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "span",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: !_vm.isCurrencyMatchHotelCurrency,
                                            expression:
                                              "!isCurrencyMatchHotelCurrency"
                                          }
                                        ],
                                        staticClass:
                                          "text-sm text-grey-dark block mt-4 mb-16"
                                      },
                                      [
                                        _vm._v(
                                          "\n                    ( " +
                                            _vm._s(
                                              _vm.hotelHotelOriginCurrency
                                            ) +
                                            " " +
                                            _vm._s(
                                              _vm._f("currency")(
                                                _vm.resultDepositCoupon
                                                  .depositPrice,
                                                {
                                                  outputCurrency:
                                                    _vm.hotelHotelOriginCurrency
                                                }
                                              )
                                            ) +
                                            " )\n                  "
                                        )
                                      ]
                                    )
                                  ])
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "order-price__row text-sm text-grey-darker mb-8"
                                },
                                [
                                  _c("label", [
                                    _vm._v(_vm._s(_vm.$t("remainTotal")))
                                  ]),
                                  _c("div", { staticClass: "text-right" }, [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "block text-sm text-grey-darkest"
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.selectedCurrency) + " "
                                        ),
                                        _c("big", { staticClass: "text-sm" }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("currency")(
                                                _vm.resultDepositCoupon
                                                  .remainPrice
                                              )
                                            )
                                          )
                                        ])
                                      ],
                                      1
                                    ),
                                    _c(
                                      "span",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: !_vm.isCurrencyMatchHotelCurrency,
                                            expression:
                                              "!isCurrencyMatchHotelCurrency"
                                          }
                                        ],
                                        staticClass:
                                          "text-sm text-grey-dark block mt-4 mb-16"
                                      },
                                      [
                                        _vm._v(
                                          "\n                    ( " +
                                            _vm._s(
                                              _vm.hotelHotelOriginCurrency
                                            ) +
                                            " " +
                                            _vm._s(
                                              _vm._f("currency")(
                                                _vm.resultDepositCoupon
                                                  .remainPrice,
                                                {
                                                  outputCurrency:
                                                    _vm.hotelHotelOriginCurrency
                                                }
                                              )
                                            ) +
                                            " )\n                  "
                                        )
                                      ]
                                    )
                                  ])
                                ]
                              )
                            ]
                          : _vm._e(),
                        !_vm.isCurrencyMatchHotelCurrency
                          ? [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "text-sm text-grey-dark block mt-16 leading-normal"
                                },
                                [
                                  _c("i", {
                                    staticClass: "owl-status-circle-info"
                                  }),
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.$t("currencyConvertNotice", {
                                          selectedCurrency:
                                            _vm.selectedCurrency,
                                          hotelCurrency:
                                            _vm.hotelHotelOriginCurrency
                                        })
                                      ) +
                                      "\n              "
                                  )
                                ]
                              )
                            ]
                          : _vm._e()
                      ],
                      2
                    )
                  ]
                ),
                _c(
                  "blockCard",
                  {
                    staticClass: "payment-ways",
                    attrs: { title: _vm.$t("paymentType") }
                  },
                  [
                    _vm.isOrderAllowPay
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "payment-ways__options flex flex-col flex-wrap leading-loose md:flex-row md:items-center"
                          },
                          [
                            _vm._l(_vm.activedWays, function(way) {
                              return [
                                _vm.isApplePay(way.type)
                                  ? _c(
                                      "div",
                                      {
                                        key: way.type,
                                        staticClass:
                                          "payment-ways__options__option",
                                        on: {
                                          click: function($event) {
                                            return _vm.onSelectPaymentWay(
                                              way.type
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "owl-radio-wrap",
                                            class: {
                                              disabled: _vm.isSubmitting
                                            }
                                          },
                                          [
                                            _c(
                                              "label",
                                              {
                                                attrs: {
                                                  for:
                                                    "payment-ways__options__option__" +
                                                    way.type
                                                }
                                              },
                                              [
                                                _c("input", {
                                                  attrs: {
                                                    type: "radio",
                                                    id:
                                                      "payment-ways__options__option__" +
                                                      way.type,
                                                    name: way.type,
                                                    disabled: _vm.isSubmitting
                                                  },
                                                  domProps: {
                                                    checked:
                                                      way.type ===
                                                      _vm.customer.paymentWay
                                                  }
                                                }),
                                                _c("div", {
                                                  staticClass: "owl-radio"
                                                }),
                                                _vm.paymentWayLogo(way.type)
                                                  ? [
                                                      _c("img", {
                                                        directives: [
                                                          {
                                                            name: "tippy",
                                                            rawName: "v-tippy"
                                                          }
                                                        ],
                                                        attrs: {
                                                          src: _vm.paymentWayLogo(
                                                            way.type
                                                          ),
                                                          alt: way.type,
                                                          title: _vm.$t(
                                                            way.type
                                                          )
                                                        }
                                                      })
                                                    ]
                                                  : [
                                                      _c(
                                                        "span",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "tippy",
                                                              rawName: "v-tippy"
                                                            }
                                                          ],
                                                          staticClass:
                                                            "uppercase",
                                                          attrs: {
                                                            title: _vm.$t(
                                                              way.type
                                                            )
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              way.type.includes(
                                                                "atm"
                                                              )
                                                                ? _vm.$t(
                                                                    "atm-transfer"
                                                                  )
                                                                : _vm.$t(
                                                                    way.type
                                                                  )
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    ]
                                              ],
                                              2
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ]
                            })
                          ],
                          2
                        )
                      : _c(
                          "div",
                          {
                            staticClass:
                              "text-orange flex items-center font-bold"
                          },
                          [
                            _c("i", {
                              staticClass: "owl-status-triangle-warning mr-8"
                            }),
                            _c("span", [
                              _vm._v(_vm._s(_vm.$t("payments_not_allowed")))
                            ])
                          ]
                        ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.isInlineCreditCard,
                            expression: "isInlineCreditCard"
                          }
                        ],
                        staticClass:
                          "orderer-info border border-grey-light my-8 p-16 rounded"
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "form-group w-full sm:w-1/2 sm:pr-24"
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "is-required",
                                attrs: { for: "cardNumber" }
                              },
                              [_vm._v(_vm._s(_vm.$t("cardNumber")))]
                            ),
                            _c("cleave", {
                              staticClass: "input",
                              class: {
                                "input-error": _vm.validation.firstError(
                                  "stripeCard.number"
                                )
                              },
                              attrs: {
                                options: _vm.cardNumberOptions,
                                placeholder: "**** **** **** ****",
                                disabled: _vm.isSubmitting
                              },
                              model: {
                                value: _vm.stripeCard.number,
                                callback: function($$v) {
                                  _vm.$set(_vm.stripeCard, "number", $$v)
                                },
                                expression: "stripeCard.number"
                              }
                            }),
                            _vm.validation.firstError("stripeCard.number")
                              ? _c(
                                  "span",
                                  { staticClass: "format-error-notify" },
                                  [_vm._v(_vm._s(_vm.$t("errorMsg.empty")))]
                                )
                              : _vm._e()
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "form-group w-full sm:w-1/2 sm:pr-24"
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "is-required",
                                attrs: { for: "cardExpiryDate" }
                              },
                              [_vm._v(_vm._s(_vm.$t("cardExpiryDate")))]
                            ),
                            _c("cleave", {
                              staticClass: "input",
                              class: {
                                "input-error": _vm.validation.firstError(
                                  "stripeCard.expiryDate"
                                )
                              },
                              attrs: {
                                options: _vm.expiryDateOptions,
                                placeholder: "MM/YY",
                                disabled: _vm.isSubmitting
                              },
                              model: {
                                value: _vm.stripeCard.expiryDate,
                                callback: function($$v) {
                                  _vm.$set(_vm.stripeCard, "expiryDate", $$v)
                                },
                                expression: "stripeCard.expiryDate"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "form-group w-full sm:w-1/2 sm:pr-24"
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "is-required",
                                attrs: { for: "cardCvc" }
                              },
                              [_vm._v(_vm._s(_vm.$t("cardCvc")))]
                            ),
                            _c("cleave", {
                              staticClass: "input",
                              class: {
                                "input-error": _vm.validation.firstError(
                                  "stripeCard.cvc"
                                )
                              },
                              attrs: {
                                options: _vm.cvcOptions,
                                placeholder: "***",
                                disabled: _vm.isSubmitting
                              },
                              model: {
                                value: _vm.stripeCard.cvc,
                                callback: function($$v) {
                                  _vm.$set(_vm.stripeCard, "cvc", $$v)
                                },
                                expression: "stripeCard.cvc"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.customer.paymentWay.includes(
                                  "paynow"
                                ),
                                expression:
                                  "customer.paymentWay.includes('paynow')"
                              }
                            ],
                            staticClass: "form-group w-full sm:w-1/2 sm:pr-24"
                          },
                          [
                            _c("span", {
                              staticClass: "text-sm text-grey-dark",
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.$t("payNowCreditCardNote")
                                )
                              }
                            })
                          ]
                        )
                      ]
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.isStripeFuturePay,
                            expression: "isStripeFuturePay"
                          }
                        ],
                        attrs: { id: "stripe-payment-form" }
                      },
                      [
                        _c("div", { staticClass: "form-row" }, [
                          _c(
                            "label",
                            { attrs: { for: "stripe-card-element" } },
                            [_vm._v("Credit or debit card")]
                          ),
                          _c("div", { staticClass: "flex" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "stripe-card-element-input w-full mr-8"
                              },
                              [
                                _c("div", {
                                  attrs: { id: "stripe-card-element" }
                                }),
                                _c("div", {
                                  attrs: {
                                    id: "stripe-card-errors",
                                    role: "alert"
                                  }
                                })
                              ]
                            )
                          ])
                        ])
                      ]
                    ),
                    _vm.customer.paymentWay === "atm"
                      ? _c("div", { staticClass: "my-16 flex items-center" }, [
                          _c("i", {
                            staticClass:
                              "owl-status-triangle-warning text-orange text-xl mr-8"
                          }),
                          _c(
                            "p",
                            { staticClass: "text-grey-darkest text-sm" },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$t("paymentNote.atm")) +
                                  "\n            "
                              )
                            ]
                          )
                        ])
                      : _vm._e(),
                    _c(
                      "div",
                      {
                        staticClass:
                          "payment-agreement__row border-t-1 border-grey-lighter pt-24"
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "owl-checkbox-wrap",
                            class: {
                              error: _vm.validation.firstError(
                                "isAgreementChecked"
                              ),
                              disabled: _vm.isSubmitting
                            }
                          },
                          [
                            _vm.isTaiwan && _vm.isZhTw
                              ? _c(
                                  "blockCard",
                                  { staticClass: "bg-orange-lightest" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "notice flex items-center mb-12"
                                      },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "owl-status-triangle-warning text-orange text-xl mr-8"
                                        }),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "text-grey-darkest text-sm"
                                          },
                                          [_vm._v("請注意")]
                                        )
                                      ]
                                    ),
                                    _c("ul", { staticClass: "list-block" }, [
                                      _c(
                                        "li",
                                        {
                                          staticClass:
                                            "leading-normal text-grey-darkest text-sm whitespace-pre-wrap"
                                        },
                                        [
                                          _vm._v(
                                            "送出後下一頁會連結至所選擇金流頁面，請耐心等候訂購成功頁面出現，請勿在金流等候頁面關閉視窗、重新整裡或點選回上頁，以免造成訂購失敗或重複付款事宜。"
                                          )
                                        ]
                                      ),
                                      _c(
                                        "li",
                                        {
                                          staticClass:
                                            "leading-normal text-grey-darkest text-sm whitespace-pre-wrap"
                                        },
                                        [
                                          _vm._v("旅宿業者或訂房系統商"),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "font-bold text-grey-darkest"
                                            },
                                            [_vm._v("不會以任何形式")]
                                          ),
                                          _vm._v("要求您前往操作"),
                                          _c(
                                            "span",
                                            { staticClass: "text-red-dark" },
                                            [
                                              _vm._v(
                                                "ATM解除扣款、變更付款方式、補繳金額、要求轉帳、解除分期、購券刷退退款"
                                              )
                                            ]
                                          ),
                                          _vm._v("等操作，亦不會要求您提供"),
                                          _c(
                                            "span",
                                            { staticClass: "text-red-dark" },
                                            [
                                              _vm._v(
                                                "信用卡帳號資料、銀行帳號相關資料"
                                              )
                                            ]
                                          ),
                                          _vm._v("。若接到可疑電話，請"),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "font-bold text-grey-darkest"
                                            },
                                            [
                                              _vm._v(
                                                "務必致電旅宿業者確認，或撥打警政署反詐騙諮詢專線165，請勿回撥可疑來電，提醒您務必提高警覺！"
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ])
                                  ]
                                )
                              : _vm._e(),
                            _c(
                              "label",
                              { attrs: { for: "payment-agreement" } },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.isAgreementChecked,
                                      expression: "isAgreementChecked"
                                    }
                                  ],
                                  class: { disabled: _vm.isSubmitting },
                                  attrs: {
                                    type: "checkbox",
                                    id: "payment-agreement",
                                    name: "payment-agreement"
                                  },
                                  domProps: {
                                    checked: Array.isArray(
                                      _vm.isAgreementChecked
                                    )
                                      ? _vm._i(_vm.isAgreementChecked, null) >
                                        -1
                                      : _vm.isAgreementChecked
                                  },
                                  on: {
                                    change: function($event) {
                                      var $$a = _vm.isAgreementChecked,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            (_vm.isAgreementChecked = $$a.concat(
                                              [$$v]
                                            ))
                                        } else {
                                          $$i > -1 &&
                                            (_vm.isAgreementChecked = $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1)))
                                        }
                                      } else {
                                        _vm.isAgreementChecked = $$c
                                      }
                                    }
                                  }
                                }),
                                _c("div", { staticClass: "owl-checkbox" }),
                                _c("span", [
                                  _vm._v(_vm._s(_vm.$t("agreement")))
                                ])
                              ]
                            )
                          ],
                          1
                        )
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "payment-agreement__row flex flex-col md:flex-row justify-between"
                      },
                      [
                        _c(
                          "button",
                          {
                            staticClass:
                              "pre-step btn border-1 md:border-none md:btn-lg text-grey flex-no-shrink w-full md:w-auto mb-16 md:mb-0",
                            attrs: { disabled: _vm.isSubmitting },
                            on: { click: _vm.preStep }
                          },
                          [_vm._v(_vm._s(_vm.$t("pre")))]
                        ),
                        _c(
                          "button",
                          {
                            staticClass:
                              "btn md:btn-lg btn-solid btn-blue w-full md:w-auto md:px-80",
                            attrs: { disabled: _vm.isSubmitButtonDisabled },
                            on: { click: _vm.onSubmitBtnClick }
                          },
                          [
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: !_vm.isSubmitting,
                                    expression: "!isSubmitting"
                                  }
                                ]
                              },
                              [_vm._v(_vm._s(_vm.$t("sendOrder")))]
                            ),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.isSubmitting,
                                    expression: "isSubmitting"
                                  }
                                ]
                              },
                              [_vm._v(_vm._s(_vm.$t("processing")))]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                )
              ],
              1
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }