<template>
  <div class="mb-16 pb-16 border-b-1 border-grey-lighter">
    <p class="font-medium text-base mb-16">{{ plan.roomName }}</p>
    <div class="flex justify-between mb-16">
      <p>{{ plan.planTitle }}</p>
      <p>x 1 {{$t('room')}}</p>
    </div>
    <div class="flex justify-between mb-16">
      <span>{{ selectedCurrency }} {{ roomTotalPrice | currency }}</span>
      <span>x {{ this['search/nights'] }} {{ $t('night', this['search/nights']) }}</span>
    </div>
    <div class="flex justify-between mb-16" v-if="hasExtraReception">
      <span>{{ selectedCurrency }} {{ extraReceptionTotalPrice | currency }}</span>
      <span>{{`+ ${extraReceptionOnIndex.adults} ${$t('extraReception.adult')}, + ${extraReceptionOnIndex.children} ${$t('extraReception.child')}`}}</span>
    </div>
    <div class="flex justify-between">
      <span>{{$t('total')}}</span>
      <span class="font-bold">{{ selectedCurrency }} {{ totalPrice | currency }}</span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'room-detail',
  props: {
    index: {
      type: Number,
      default: 0
    },
    plan: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    ...mapGetters([
      'selectedCurrency',
      'search/nights',
      'order/extraReceptionPriceConfig'
    ]),
    roomTotalPrice () {
      return this.plan.avgPrice * this['search/nights']
    },
    extraReceptionOnIndex () {
      return this.plan.extraReception[this.index]
    },
    hasExtraReception ({ extraReceptionOnIndex }) {
      return extraReceptionOnIndex.adults > 0 || extraReceptionOnIndex.children > 0
    },
    extraReceptionTotalPrice ({ extraReceptionOnIndex }) {
      return (extraReceptionOnIndex.adults * this['order/extraReceptionPriceConfig'].adults + extraReceptionOnIndex.children * this['order/extraReceptionPriceConfig'].children) * this['search/nights']
    },
    totalPrice () {
      return this.roomTotalPrice + this.extraReceptionTotalPrice
    }
  }
}
</script>

<style lang='sass' scoped>

</style>
